<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("feedback.time") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("feedback.process") }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('feedback.ph.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("feedback.user") }}</span>
          <el-input class="inp" size="small" v-model="formData.valUser"
            :placeholder="$t('feedback.ph.user')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t("feedback.content") }}</span>
          <el-input class="inp" size="small" v-model="formData.valContent"
            :placeholder="$t('feedback.ph.content')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t("g.search") }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
      </div>
      <el-table @row-click="func.rowDblClick" :data="formData.tableData" style="width: 100%">
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="CreateAtStr" :label="$t('feedback.time')" width="180">
        </el-table-column>
        <el-table-column prop="Username" width="180" :label="$t('feedback.user')">
        </el-table-column>
        <el-table-column width="160" :label="$t('feedback.process')">
          <template #default="scope">
            <el-tag @click.stop="func.handleStatus(scope.row)" :type="scope.row.Answer ? 'success' : 'danger'"
              effect="dark">
              {{ scope.row.status }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Question" :label="$t('feedback.content')">
        </el-table-column>
        <el-table-column prop="Answer" width="300" :label="$t('feedback.reply')">
        </el-table-column>
        <el-table-column width="150" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag @click="func.rowDblClick(scope.row)">{{ $t('g.see') }}</el-tag>
            <el-tag @click="func.handleStatus(scope.row)" style="margin-left: 20px;">{{ $t('g.edit') }}</el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('tips')" v-model="formData.dialogVisible" width="60%">
      <el-form label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('feedback.user')">
            <el-input v-model="formData.curData.Username" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('feedback.time')">
            <el-input v-model="formData.curData.CreateAtStr" disabled></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('feedback.process')">
            <el-input v-model="formData.curData.status" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('feedback.reply')">
            <el-input v-model="formData.curData.Answer" disabled></el-input>
          </el-form-item>
        </div>

        <el-form-item :label="$t('feedback.content')">
          <el-input rows="5" type="textarea" v-model="formData.curData.Question" disabled></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="formData.dialogVisible = false">{{ $t("g.btn_confirm") }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :title="$t('tips')" v-model="formData.dialogVisibleStatus" width="60%">
      <el-form label-width="80px" :model="formData">
        <!-- <el-form-item label="处理进度">
          <el-select class="el-select-my" size="small" v-model="formData.valNewStatus" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.updateStatuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </el-form-item> -->
        <el-form-item :label="$t('feedback.reply')">
          <el-input rows="5" type="textarea" v-model="formData.valReply"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.dialogVisibleStatus = false">{{ $t("g.btn_cancel") }}</el-button>
          <el-button type="primary" @click="func.updateStatus">{{ $t("g.btn_confirm") }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import { shortcuts } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage } from 'element-plus'
import i18n from '@/data/i18n/index'

export default {
  name: 'AfterSellFeedBack',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t("feedback.statuses.all"),
        value: 0
      },
      {
        label: t("feedback.statuses.undeal"),
        value: 1
      },
      {
        label: t("feedback.statuses.done"),
        value: 2
      }
    ]
    const render = {
      statuses,
      updateStatuses: [
        {
          label: t("feedback.statuses.doing"),
          value: 2
        },
        {
          label: t("feedback.statuses.done"),
          value: 3
        }
      ],
      shortcuts
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: 0,
      valType: 0,
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 500,
      dialogVisibleStatus: false,
      valNewStatus: 2,
      valReply: '',
      valUser: '',
      valDataId: 0,
      valContent: ''
    })
    const func = {
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      rowDblClick(row, column, e) {
        if (column && column.label == t('g.operation')) {
          return;
        }
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      handleStatus(row) {
        if (row.Answer) {
          return
        }
        formData.dialogVisibleStatus = true
        formData.valDataId = row.Id
        console.log('handleStatus', row)
      },
      async updateStatus() {
        formData.dialogVisibleStatus = false
        console.log('updateStatus', formData.valDataId, formData.valNewStatus, formData.valReply)
        try {
          let res = await axios.post('/admin/faq', {
            id: formData.valDataId,
            answer: formData.valReply
          })
          //刷新数据
          func.loadData(formData.curPage)
        } catch (e) {
          ElMessage({
            type: 'error',
            message: t("feedback.error")
          })
        }
        formData.valReply = ""
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valStatus) {
            params.status = formData.valStatus
          }
          if (formData.valUser) {
            params.name = formData.valUser
          }
          if (formData.valContent) {
            params.question = formData.valContent
          }
          if (formData.valTime) {
            params.startAt = formData.valTime[0] + " 00:00:00"
            params.endAt = formData.valTime[1] + " 23:59:59"
          }
          console.log(JSON.stringify(params));

          let res = await axios.get(`/admin/faq`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = item.CreateAt == item.UpdateAt ? "" : toDay(item.UpdateAt)
            item.status = item.Answer ? t("feedback.statuses.done") : t("feedback.statuses.undeal")
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 50rem;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}
</style>
