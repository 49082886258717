<template>
  <div class="component">
    <div class="chart-box">
      <!-- <div class="header">
        <el-select size="small" v-model="formData.valCabinet" placeholder="请选择">
            <el-option
              v-for="item in formData.cabinets"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
      </div> -->
      <div :id="formData.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'

export default {
  name: 'regs',
  props:{
    agentCode:[String,Number],
    devType:{
      type:Number,
      default:0,
    },
    cycle:{
      type:Number,
      default:1,
    },
    startAt:{
      type:Date,
      default:null
    },
    id:{
      type:String,
      default:"reg"
    }
  },
  setup (props) {
    const t = i18n.global.t
    
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    let myChart = null
    const cabinets = [{
      id: 0,
      name: t('device.types.all')
    }]
    cabinets.push(...[{
        name: t('device.types.ebox'),
        id: 1
      }, {
        name: t('device.types.cbox'),
        id: 2
      }, {
        name: t('device.types.pile'),
        id: 3
      }
    ])
    const id = "chartid-user-" + props.id
    const formData = reactive({
      valCabinet: 0,
      id,
      cabinets
    })
    const initChart = () => {
      const chartDom = document.getElementById(id)
      myChart = echarts.init(chartDom)
      loadData()
    }

    watch(() => formData.valCabinet, () => {
      // 数值改变更新图表
    })

    const loadData = async () => {
      if(!props.startAt)return
      const params = {
        startAt:props.startAt.getTime() / 1000,
        cycle:props.cycle,
        agentCode:props.agentCode || "",
        devType:props.devType,
      }
      let res = await axios.get("/admin/analyze/user/trend",{
        params
      })

      const legend = [res.lastKey, res.curKey]
      const xAxis = []
      const data = [[], []]
      for (let i = 0; i < res.cur.length; i++) {
        xAxis.push(res.cur[i].Key)
        let lastItem = i >= res.last.length ? 0 : res.last[i].Val
        data[1].push(res.cur[i].Val)
        data[0].push(lastItem)
      }
      const option = {
        title: {
          text: t('chart.site_user_trend')
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: legend[0],
            type: 'line',
            data: data[0]
          },
          {
            name: legend[1],
            type: 'line',
            data: data[1]
          }
        ]
      }
      // setTimeout(()=>{
      //   option && myChart.setOption(option)
      // },3000)
      myChart.setOption(option,true)
      console.log("set option",option)
    }
    onMounted(() => {
      initChart()
    })
    return {
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
