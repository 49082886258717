<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('decision.time') }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.status') }}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.statuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('order.name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valTel" :placeholder="$t('order.inp.name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('order.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderSn"
            :placeholder="$t('order.inp.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDeviceId"
            :placeholder="$t('order.inp.device_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.mobile') }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone" :placeholder="$t('member.mobile')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
          <el-icon @click="func.refresh()" class="header-icon">
            <Refresh />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" style="width: 100%" @row-click="func.showDetails">
        <el-table-column type="expand">
          <template #default="props">
            <div class="table-expand">
              <div class="devimg">
                <div class="img-item" v-for="(item, idx) in props.row.DevImageList" :key="idx">
                  <div class="label">{{ item.TypeName }}</div>
                  <el-image class="img" :src="item.Image" :preview-src-list="props.row.ImagePre" fit="cover">
                  </el-image>
                </div>
              </div>
              <p v-if="props.row.FailReason">{{ $t('order.failed_reason') }}: {{ props.row.FailReason }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="Id" width="100" v-if="!isOperator()">
        </el-table-column>
        <el-table-column prop="OrderSn" :label="$t('order.no')" width="200">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('g.agent_code')" width="80">
        </el-table-column>
        <el-table-column prop="UserInfo.Nickname" width="80" :label="$t('bill.nickname')">
        </el-table-column>
        <el-table-column prop="UserInfo.Mobile" width="120" :label="$t('member.mobile')">
        </el-table-column>
        <el-table-column prop="UserInfo.IdName" width="120" :label="$t('member.IdName')">
        </el-table-column>
        <el-table-column prop="DeviceId" width="250" :label="$t('device.no')">
        </el-table-column>
        <el-table-column prop="DeviceSiteName" width="120" :label="$t('device.site_name')">
        </el-table-column>
        <el-table-column prop="ReturnOrderInfo.BatteryId" width="240" :label="$t('member.battery_no')"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="ReturnOrderInfo.BatterySoc" width="90" :label="$t('device.battery_soc')">
        </el-table-column>
        <el-table-column prop="ReturnOrderInfo.SlotId" width="80" :label="$t('order.slot_return')">
        </el-table-column>
        <el-table-column prop="ReturnOrderInfo.Money" width="60" :label="$t('device.deposit')">
        </el-table-column>
        <el-table-column prop="PayWayStr" width="80" :label="$t('order.back_way')">
        </el-table-column>

        <el-table-column width="80" :label="$t('member.status')">
          <template #default="scope">
            <el-tag
              :type="scope.row.StatusStr == $t('member.statuses.success') ? 'success' : (scope.row.StatusStr == $t('member.statuses.failed') ? 'danger' : 'warning')"
              effect="dark">
              {{ scope.row.StatusStr }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="150" :label="$t('order.failed_reason')" prop="ReturnOrderInfo.Result">
        </el-table-column>
        <el-table-column prop="Remark" width="80" :label="$t('order.remark')" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="CreatedAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>

      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible">
      <el-descriptions border column="2">
        <el-descriptions-item :label="$t('order.no')">{{ formData.curData.OrderSn }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserInfo.Nickname
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.UserInfo.Mobile }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.IdName')">{{ formData.curData.UserInfo.IdName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DeviceId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.site_name')">{{ formData.curData.DeviceSiteName
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.battery_no')">{{ formData.curData.ReturnOrderInfo.BatteryId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.battery_soc')">{{ formData.curData.ReturnOrderInfo.BatterySoc
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.slot_return')">{{ formData.curData.ReturnOrderInfo.SlotId
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.deposit')">{{ formData.curData.ReturnOrderInfo.Money
          }}</el-descriptions-item>

        <el-descriptions-item :label="$t('order.back_way')">{{ formData.curData.PayWayStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.status')">
          <el-tag
            :type="formData.curData.StatusStr == $t('member.statuses.success') ? 'success' : (formData.curData.StatusStr == $t('member.statuses.failed') ? 'danger' : 'warning')"
            effect="dark">
            {{ formData.curData.StatusStr }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('order.failed_reason')">{{ formData.curData.ReturnOrderInfo?.Result
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('order.remark')">{{ formData.curData.Remark }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted } from 'vue'
import axios from 'axios'
import { operators, updateOperators, shortcuts } from '@/data/default'
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'OrderBatteryReturn',
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('member.statuses.all'),
        value: 2
      },
      {
        label: t('member.statuses.success'),
        value: 1
      },
      {
        label: t('member.statuses.failed'),
        value: 0
      }
    ]
    const render = {
      shortcuts,
      statuses,
      operators
    }
    const formData = reactive({
      tableData,
      curData: {},
      valOperator: 0,
      valStatus: 2,
      valTime: '',
      valTel: '',
      valOrderSn: '',
      valDeviceId: '',
      dialogDisplayVisible: false,
      // pagination
      curPage: 1,
      pageSize: 10,
      total: 1
    })
    const func = {
      showDetails(row) {
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        console.log('formData', formData)
        func.loadData(1)
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      refresh() {
        formData.valOperator = 0
        formData.valStatus = 2
        formData.valTime = ''
        formData.valTel = ''
        func.loadData(1)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valOrderSn) {
            params.orderSn = formData.valOrderSn
          }
          if (formData.valDeviceId) {
            params.deviceId = formData.valDeviceId
          }
          if (formData.valOperator) {
            params.agentcode = formData.valOperator
          }
          if (formData.valTel) {
            params.nickname = formData.valTel
          }
          if (formData.valStatus != 2) {
            params.status = formData.valStatus
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone
          }
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/order/return`, {
            params
          })
          // console.log(JSON.stringify(res));

          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.StatusStr = item.DeviceStatus === 1 ? (item.Status == 2 ? t('order.returned') : t('member.statuses.success')) : t('member.statuses.failed')
            item.PayWayStr = ""
            if (item.PayWay == 1) {
              item.PayWayStr = t('order.pay_ways.balance')
            } else if (item.PayWay == 2) {
              item.PayWayStr = t('order.pay_ways.wechat')
            } else if (item.PayWay == 5) {
              item.PayWayStr = t('order.pay_ways.alipay')
            }
            item.ImagePre = []
            if (!item.DevImageList) {
              item.DevImageList = []
            }
            item.DevImageList.map((item2) => {
              item2.TypeName = ""
              switch (item2.OperType) {
                case 0:
                  item2.TypeName = t('order.imgs.t0')
                  break
                case 1:
                  item2.TypeName = t('order.imgs.t1')
                  break
                case 2:
                  item2.TypeName = t('order.imgs.t2')
                  break
                case 3:
                  item2.TypeName = t('order.imgs.t3')
                  break
              }
              item.ImagePre.push(item2.Image)
            })
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      console.log("onMounted")
      updateOperators()
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .inp-block {
    display: block;
  }

  .table-expand {
    padding-left: 4rem;
    padding-top: 1rem;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 33%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }
}
</style>
