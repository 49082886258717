<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t('card.expire') }}</span>
          <el-date-picker class="inp"
          value-format="YYYY-MM-DD"
          v-model="formData.valTime"
          size="small" type="daterange" unlink-panels
            :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')" :end-placeholder="$t('g.end_date')"
            :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("card.number") }}</span>
          <el-input class="inp" size="small" v-model="formData.cardNum" :placeholder="$t('g.please_input') + $t('card.number')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('card.bind_user') }}</span>
          <el-input class="inp" size="small" v-model="formData.userAccount" :placeholder=" $t('g.please_input') + $t('card.please_input_account')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize }}</span>
        <div class="header-icons">

          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
          <el-icon @click="func.add()" class="header-icon">
            <Plus />
          </el-icon>
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true"
      @row-click="func.showDetails"
      style="width: 100%">
        <el-table-column prop="Id" label="Id" width="80">
        </el-table-column>
        <el-table-column prop="Number" :label="$t('card.number')" width="220">
        </el-table-column>
        <el-table-column :label="$t('card.bind_user')" width="140">
          <template #default="scope">
            <div v-if="scope.row.UserId == 0">{{ $t("card.bind_status_no") }}</div>
            <div v-else>{{ scope.row.UserInfo.Account }}</div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('card.bind_package')" width="140">
          <template #default="scope">
            <div v-if="scope.row.UserInfo&&scope.row.UserInfo.UserComboInfo&&scope.row.UserInfo.UserComboInfo.ComboId == 0">/</div>
            <div v-else-if="scope.row.UserInfo&&scope.row.UserInfo.UserComboInfo&&scope.row.UserInfo.UserComboInfo.Combo">
              {{ func.formMatCombo(scope.row.UserInfo.UserComboInfo.Combo) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="DepositMoney" width="120" :label="$t('device.deposit')">
        </el-table-column>
        <el-table-column prop="AgentCode" width="120" :label="$t('g.operator')">
        </el-table-column>
        <el-table-column prop="EndAtStr" width="180" :label="$t('card.expire')">
        </el-table-column>
        <el-table-column width="180" :label="$t('g.residue_degree')">
          <template #default="scope">
              <span v-if="scope.row.UserInfo&&scope.row.UserInfo.UserComboInfo&&scope.row.UserInfo.UserComboInfo.Combo&&scope.row.UserInfo.UserComboInfo.Combo.Type==2">{{scope.row.UserInfo.UserComboInfo.RemainTimes}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column width="220" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <!-- 可以绑定 -->
            <div v-if="scope.row.UserId == 0">
              <el-tag @click="func.rowDblClick(scope.row)">{{ $t('g.edit') }}</el-tag>
              <el-tag style="margin-left: 1rem;" @click="func.rowBindClick(scope.row)">{{ $t('card.bind_user') }}</el-tag>
              <el-tag style="margin-left: 1rem;color:gray;cursor: default;">{{ $t('card.unbind_user') }}</el-tag>
            </div>
            <div v-else>
              <!-- 已经绑定 -->
              <el-tag style="color:gray;cursor: default;">{{ $t('g.edit') }}</el-tag>
              <el-tag style="margin-left: 1rem;color:gray;cursor: default;">{{ $t('card.bind_user') }}</el-tag>
              <el-tag style="margin-left: 1rem;" @click="func.unBindUser(scope.row)">{{ $t('card.unbind_user') }}</el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
    :title="$t('g.detail')" 
    v-model="formData.dialogDisplayVisible"
    >
	<el-descriptions border column="3">
      <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
      <el-descriptions-item :label="$t('card.number')">{{ formData.curData.Number }}</el-descriptions-item>
      <el-descriptions-item :label="$t('card.bind_user')"> <div v-if="formData.curData.UserId == 0">{{ $t("card.bind_status_no") }}</div>
            <div v-else>{{ formData.curData.UserInfo.Account }}</div></el-descriptions-item>
      <el-descriptions-item :label="$t('card.bind_package')"><div v-if="formData.curData.UserInfo.UserComboInfo.ComboId == 0">/</div>
            <div v-else>{{ func.formMatCombo(formData.curData.UserInfo.UserComboInfo.Combo) }}</div></el-descriptions-item>
      <el-descriptions-item :label="$t('device.deposit')">{{ formData.curData.DepositMoney }}</el-descriptions-item>
      <el-descriptions-item :label="$t('g.operator')">{{ formData.curData.AgentCode }}</el-descriptions-item>
      <el-descriptions-item :label="$t('card.expire')">{{ formData.curData.EndAtStr }}</el-descriptions-item>
      <el-descriptions-item :label="$t('g.residue_degree')"> <span v-if="formData.curData.UserInfo.UserComboInfo.Combo.Type==2">{{formData.curData.UserInfo.UserComboInfo.RemainTimes}}</span></el-descriptions-item>
      <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>

	</el-descriptions>
  </el-dialog>
    <el-dialog :title="$t('card.bind_user')" v-model="formData.dialogVisible"  width="600px">
      <el-form  label-width="80px" :model="formData.bindUser">
        <el-form-item :label="$t('card.bind_user')">
          <el-select v-model="formData.bindUser.userId" filterable remote reserve-keyword
            :placeholder="$t('card.please_input_account')" :remote-method="func.searchUsers" :loading="loading" clearable>
            <el-option v-for="item in formData.users" :key="item.Id" :label="item.Account" :value="item.Id" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('card.bind_package')">
          <!-- <el-input size="small" type="number" v-model="formData.bindUser.comboId"></el-input> -->
          <el-select class="inp" size="small" v-model="formData.bindUser.comboId" :placeholder="$t('card.bind_package')">
            <el-option v-for="item in formData.packages" :key="item.Id" :label="func.formMatCombo(item)" :value="item.Id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.doBindUser()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>


    <el-dialog :title="$t('g.detail')" v-model="formData.addDialogVisible"  width="600px">
      <el-form  label-width="80px" :model="formData.card">
        <el-form-item :label="$t('card.number')">
          <el-input size="small"  v-model="formData.card.number"></el-input>
        </el-form-item>
        <el-form-item :label="$t('card.deposit')">
          <el-input size="small" type="number" v-model="formData.card.deposit"></el-input>
        </el-form-item>
        <div class="item" v-if="isAdmin()">
          <el-form-item :label="$t('g.operator')">
          <el-select class="inp" size="small" v-model="formData.card.agentCode" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operatorList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.addCard()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
  
<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operatorList } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import i18n from '@/data/i18n/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { isAdmin } from '@/data/token'

export default {
  name: 'BatteryList',
  components: {
  },
  setup() {
    const tableData = []

    const render = {
      shortcuts,
      operatorList
    }
    const formData = reactive({
      tableData,
      card: {
        number: 0,
        deposit: 0,
      },
      bindUser: {},
      packages: [],
      valTime: '',
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      dialogVisible: false,
      addDialogVisible: false,
      dialogDisplayVisible:false,
      users: [],
      loadingUser: false,
    })
    const func = {
      showDetails(row,column){
        if(column&&column.label==t('g.operation')){
          return;
        }
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.cardNum) {
            params.cardNum = formData.cardNum
          }
          if (formData.userAccount) {
            params.userAccount = formData.userAccount
          }
          if (formData.valCode) {
            params.agentCode = formData.valCode
          }
          
          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
          }
          console.log(JSON.stringify(formData.valTime));
          console.log(JSON.stringify(params));
          
          if (formData.valDevName) {
            params.devId = formData.valDevName
          }
          let res = await axios.get(`/admin/card/get`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt);
            if(item.UserInfo&&item.UserInfo.UserComboInfo){
              item.EndAtStr = item.UserInfo.UserComboInfo.EndAt?toDay(item.UserInfo.UserComboInfo.EndAt):'';
            }
            if(!item.UserInfo){
              item.UserInfo = {}
              item.UserInfo.UserComboInfo = {}
            }
            
          })
          formData.tableData = res.List
          // console.log(JSON.stringify(formData.tableData));
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      },
      async loadPackages(page = 1) {
        try {
          let res = await axios.get(`/admin/combo/all`, {})
          for(let item of res){
              if(item.Status==1){
                formData.packages.push(item)
              }
          }
          // formData.packages = res
        } catch (e) {
          console.log("loadData", e)
        }
      },
      rowBindClick(row, column, e) {
        console.log('rowBindClick', row, column, e)
        formData.dialogVisible = true
        formData.bindUser.id = row.Id
      },
      rowDblClick(row, column, e) {
        console.log('rowDblClick', row, column, e)
        console.log(row.Id)
        console.log(row.DepositMoney)
        console.log(row.Number)
        formData.card.id = row.Id
        formData.card.deposit = row.DepositMoney
        formData.card.agentCode = row.AgentCode
        formData.card.number = row.Number
        formData.addDialogVisible = true
      },
      add() {
        formData.addDialogVisible = true
      },
      async addCard() {
        let data = formData.card
        console.log(JSON.stringify(data),123);
        
        let url = '/admin/card/add'
        if (data.id > 0) {
          url = '/admin/card/edit'
        }
        let res = await axios.post(url, data)
        console.log('------doBindUser---')
        console.log(res)
        ElMessage({
          type: 'success',
          message: res
        })
        formData.addDialogVisible = false
        // 重置表单
        formData.card = {}
        func.loadData()
      },
      formMatCombo(item) {
        const t = i18n.global.t
        let unit = t('card.month')
        if (item.Type == 2) {
          unit = t('card.times')
        }
        return `${item.Price}${t('card.currency')}/${item.Num}${unit}`
      },
      async searchUsers(account) {
        console.log('---searchUsers--')
        console.log(account)
        if (account) {
          formData.loadingUser = true
          let params = {
            account,
          }
          try {
            let res = await axios.get(`/admin/user/findUser`, {
              params
            })
            formData.loadingUser = false
            console.log('-------search User')
            // console.log(res)
            formData.users = res
          } catch (e) {
            console.log("loadData", e)
            formData.loadingUser = false
          }
        } else {
          formData.users = []
        }
      },
      doBindUser() {
        let data = formData.bindUser;
        // console.log(JSON.stringify(data));
        
        // console.log(data)
        // const t = i18n.global.t
        // let package_id = formData.bindUser.comboId
        // let comboLabel = t('card.bind_tips')
        // for (let o of formData.packages) {
        //   if (package_id == o.Id) {
        //     console.log(JSON.stringify(o));
            
        //     comboLabel = comboLabel.replace('xx/xx', '')
        //     break
        //   }
        // }
        // console.log(JSON.stringify(comboLabel));
        
        // 确定为该卡绑定xx/xx套餐吗
        
        // ElMessageBox.confirm(comboLabel, t('tips'), {
        //   confirmButtonText: t('confirm'),
        //   cancelButtonText: t('cancel'),
        //   type: 'warning'
        // }).then(async () => {
        //   let res = await axios.post("/admin/card/bindUser", data)
        //   console.log('------doBindUser---')
        //   console.log(res)
        //   ElMessage({
        //     type: 'success',
        //     message: res
        //   })
        // }).then(() => {

        // })
        //   .catch(() => {
        //   })
        (async function (){
            console.log('------doBindUser---')
            let res = await axios.post("/admin/card/bindUser", data)
            console.log('------doBindUser---')
            console.log(res)
            ElMessage({
              type: 'success',
              message: res
            })
        })();


        formData.dialogVisible = false
        // 重置表单
        formData.bindUser = {}
        setTimeout(()=>{
           func.loadData()
        },500)
       
      },
      unBindUser(row, column, e) { //解绑用户
        // formData.bindUser.id = row.Id
        let data = {
          id:row.Id
        };
        const t = i18n.global.t
        let comboLabel = "解除绑定会清空绑定套餐，是否继续？";
        ElMessageBox.confirm(comboLabel, t('tips'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
          type: 'warning'
        }).then(async () => {
          let res = await axios.post("/admin/card/unbindCard", data)
            console.log('------doBindUser---')
            console.log(res)
            ElMessage({
              type: 'success',
              message: res
            })
        }).then(() => {

        })
          .catch(() => {
          })
        // (async function (){
        //     let res = await axios.post("/admin/card/unbindCard", data)
        //     console.log('------doBindUser---')
        //     console.log(res)
        //     ElMessage({
        //       type: 'success',
        //       message: res
        //     })
        // })();
        // func.loadData()
        setTimeout(()=>{
           func.loadData()
        },2000)
      }
    }
    onMounted(() => {
      func.loadData()
      func.loadPackages()
    })
    return {
      func,
      render,
      formData,
      isAdmin,
    }
  }
}
</script>
  
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        width: 33%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }
}
</style>
  