import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Stat from '../views/Stat.vue'
import Login from '../views/Login.vue'
import DecisionMarket from '../views/decision/market.vue'
import DecisionHot from '../views/decision/hot.vue'
import OperationPoints from '../views/operation/points.vue'
import OperationBatterys from '../views/operation/batterys.vue'
import OperationBattery from '../views/operation/OperationBattery.vue'
import OperationScatter from '../views/operation/scatter.vue'
import OperationUsers from '../views/operation/users.vue'
import OperationProperties from '../views/operation/properties.vue'
import OperationOrders from '../views/operation/orders.vue'
import OperationBikes from '../views/operation/bikes.vue'
import FinanceReport from '../views/finance/report.vue'
import LogSystem from '../views/log/system.vue'
import LogApi from '../views/log/api.vue'
import msgLog from '../views/log/msg.vue'
import electricityStat from '../views/log/electricityStat.vue'
import AfterSellFeedBack from '../views/after_sell/feedback.vue'
import MemberProfile from '../views/member/profile.vue'
import MemberBikes from '../views/member/bikes.vue'
import MemberCards from '../views/member/cards.vue'
import MemberList from '../views/member/list.vue'
import MemberAgents from '../views/member/agents.vue'
import MemberAgentsSwitch from '../views/member/agentsSwitch.vue'
import ExpensesMonthCard from '../views/expenses/monthcard.vue'
import ExpensesCombo from '../views/expenses/combo.vue'
import OrderBattery from '../views/order/battery.vue'
import OrderBike from '../views/order/bike.vue'
import OrderCabinet from '../views/order/cabinet.vue'
import OrderPile from '../views/order/pile.vue'
import OrderCbox from '../views/order/cbox.vue'
import OrderCombo from '../views/order/combo.vue'
import OrderOffline from '../views/order/offline.vue'
import OrderRecharge from '../views/order/recharge.vue'
import OrderBatteryReturn from '../views/order/batteryReturn.vue'
import temporaryRecord from '../views/order/temporaryRecord.vue'
import importRecord from '../views/order/importRecord.vue'
import EboxList from '../views/ebox/list.vue'
import openSlotsList from '../views/openSlots/list.vue'
import openSlots from '../views/openSlots/simple.vue'
import alertRecord from '../views/alertRecord/list.vue'
import activityManage from '../views/activityManage/list.vue'
import discountCoupon from '../views/activityManage/coupon.vue'
import restrictionRule from '../views/activityManage/rule.vue'
import recruitNew from '../views/activityManage/recruitNew.vue'
import announcementList from '../views/activityManage/announcementList.vue'
import withdrawFunds from '../views/activityManage/withdrawFunds.vue'
import award from '../views/activityManage/award.vue'
import CboxList from '../views/cbox/list.vue'
import PileList from '../views/pile/list.vue'
import CPboxList from '../views/cpbox/list.vue'
import BatteryList from '../views/battery/list.vue'
import BillRefund from '../views/bill/refund.vue'
import BillRefundRecord from '../views/bill/refund_record.vue'
import BillRecord from '../views/bill/record.vue'
import BillBad from '../views/bill/bad_bill.vue'
import BillSettle from '../views/bill/settle.vue'
import RightsManager from '../views/rights/manager.vue'
import RightsRole from '../views/rights/role.vue'
import Rights from '../views/rights/rights.vue'
import CardList from '../views/card/list.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/stat',
    name: 'Stat',
    component: Stat
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/rights',
    name: 'Rights',
    component: Rights
  },
  {
    path: '/rights/manager',
    name: 'RightsManager',
    component: RightsManager
  },
  {
    path: '/rights/role',
    name: 'RightsRole',
    component: RightsRole
  },
  {
    path: '/bill/refund',
    name: 'BillRefund',
    component: BillRefund
  },
  {
    path: '/bill/refund/record',
    name: 'BillRefundRecord',
    component: BillRefundRecord
  },
  {
    path: '/bill/bad',
    name: 'BillBad',
    component: BillBad
  },
  {
    path: '/bill/record',
    name: 'BillRecord',
    component: BillRecord
  },
  {
    path: '/bill/settle',
    name: 'BillSettle',
    component: BillSettle
  },
  {
    path: '/ebox',
    name: 'EboxList',
    component: EboxList
  },
  {
    path: '/openSlots',
    name: 'openSolotRecord',
    component: openSlotsList
  },,
  {
    path: '/openSlots/simple',
    name: 'openSolot',
    component: openSlots
  },
  {
    path: '/alertRecord',
    name: 'alertRecord',
    component: alertRecord
  },
  {
    path: '/activityManage',
    name: 'activityManage',
    component: activityManage
  },
  {
    path: '/activityManage/rule',
    name: 'restrictionRule',
    component: restrictionRule
  },

  {
    path: '/activityManage/coupon',
    name: 'discountCoupon',
    component: discountCoupon
  },

  {
    path: '/activityManage/award',
    name: 'awardManage',
    component: award
  },
  {
    path: '/activityManage/recruitNew',
    name: 'recruitNew',
    component: recruitNew
  },
  {
    path: '/activityManage/announcementList',
    name: 'announcementList',
    component: announcementList
  },
  {
    path: '/activityManage/withdrawFunds',
    name: 'withdrawFunds',
    component: withdrawFunds
  },
  {
    path: '/cbox',
    name: 'CboxList',
    component: CboxList
  },
  {
    path: '/cpbox',
    name: 'CPboxList',
    component: CPboxList
  },
  {
    path: '/pile',
    name: 'PileList',
    component: PileList
  },
  {
    path: '/battery',
    name: 'BatteryList',
    component: BatteryList
  },
  {
    path: '/order/battery',
    name: 'OrderBattery',
    component: OrderBattery
  },
  ,
  {
    path: '/log/electricityStat',
    name: 'electricityStat',
    component: electricityStat
  },
  {
    path: '/order/battery/return',
    name: 'OrderBatteryReturn',
    component: OrderBatteryReturn
  },
  {
    path: '/order/temporaryRecord',
    name: 'temporaryRecord',
    component: temporaryRecord
  },
  {
    path: '/order/importRecord',
    name: 'importRecord',
    component: importRecord
  },
  {
    path: '/order/combo',
    name: 'OrderCombo',
    component: OrderCombo
  },
  {
    path: '/order/recharge',
    name: 'OrderRecharge',
    component: OrderRecharge
  },
  {
    path: '/order/bike',
    name: 'OrderBike',
    component: OrderBike
  },
  {
    path: '/order/cabinet',
    name: 'OrderCabinet',
    component: OrderCabinet
  },
  {
    path: '/order/pile',
    name: 'OrderPile',
    component: OrderPile
  },
  {
    path: '/order/cbox',
    name: 'OrderCbox',
    component: OrderCbox
  },
  {
    path: '/order/offline',
    name: 'OrderOffline',
    component: OrderOffline
  },
  {
    path: '/expenses/monthcard',
    name: 'ExpensesMonthCard',
    component: ExpensesMonthCard
  },
  {
    path: '/expenses/combo',
    name: 'ExpensesCombo',
    component: ExpensesCombo
  },
  {
    path: '/member/profile',
    name: 'MemberProfile',
    component: MemberProfile
  },
  {
    path: '/member/bikes',
    name: 'MemberBikes',
    component: MemberBikes
  },
  {
    path: '/member/cards',
    name: 'MemberCards',
    component: MemberCards
  },
  {
    path: '/member/list',
    name: 'MemberList',
    component: MemberList
  },
  {
    path: '/member/agents',
    name: 'MemberAgents',
    component: MemberAgents    
  },
  {
    path: '/member/agentsSwitch',
    name: 'MemberAgentsSwitch',
    component: MemberAgentsSwitch    
  },
  {
    path: '/after_sell/feedback',
    name: 'AfterSellFeedBack',
    component: AfterSellFeedBack
  },
  {
    path: '/decision/market',
    name: 'DecisionMarket',
    component: DecisionMarket
  },
  {
    path: '/decision/host',
    name: 'DecisionHot',
    component: DecisionHot
  },
  {
    path: '/operation/points',
    name: 'OperationPoints',
    component: OperationPoints
  },
  {
    path: '/operation/batterys',
    name: 'OperationBatterys',
    component: OperationBatterys
  },
  {
    path: '/operation/OperationBattery',
    name: 'OperationBattery',
    component: OperationBattery
  },
  {
    path: '/operation/scatter',
    name: 'OperationScatter',
    component: OperationScatter
  },
  {
    path: '/operation/users',
    name: 'OperationUsers',
    component: OperationUsers
  },
  {
    path: '/operation/properties',
    name: 'OperationProperties',
    component: OperationProperties
  },
  {
    path: '/operation/orders',
    name: 'OperationOrders',
    component: OperationOrders
  },
  {
    path: '/operation/orders',
    name: 'OperationBikes',
    component: OperationBikes
  },
  {
    path: '/finance/report',
    name: 'FinanceReport',
    component: FinanceReport
  },
  {
    path: '/log/system',
    name: 'LogSystem',
    component: LogSystem
  },
  {
    path: '/log/api',
    name: 'LogApi',
    component: LogApi
  },
  {
    path: '/log/msg',
    name: 'msgLog',
    component: msgLog
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/bigScreen',
    name: 'bigScreen',
    component: () => import('../views/bigScreen/index.vue')
  },
  {
    path: '/card/list',
    name: 'CardList',
    component: CardList
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
