import axios from 'axios'
import { user } from '@/data/token'
import { ElMessage } from 'element-plus'
import { navTo } from '@/common/func'
import i18n from '@/data/i18n/index'
import qs from 'qs'
// 添加请求拦截器
axios.interceptors.request.use(
  config => {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if(config.headers['Content-Type']!='multipart/form-data'){
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data)
    }
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    if (user.token) {
      config.headers.Authorization = user.token
    }
    return config
  },
  err => {
    if (err !== undefined) {
      return Promise.reject(err)
    }
  })

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    const data = response.data
    // console.log(JSON.stringify(data));
    
    if(data.Code == 530){
      navTo('Login')
      return ""
    }
    if(data.Code != 200 &&!data.status&&data.status!=0&&data.Code!=21001){
      const t = i18n.global.t
      const key = 'err_code.'+data.Code
      let msg = t(key)
      if(msg==key || !msg){
        msg = data.Msg
      }
      ElMessage({
        type: 'error',
        message: msg
      })
      return Promise.reject(data.Msg)
    }
    if(data.status!=0){
      return data.Data
    }else{
      return data
    }
    
  },
  error => {
    // console.log(error.response.data.message);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          navTo('Login')
        // console.log('401')
      }
    }
    if (error !== undefined) {
      return Promise.reject(error.response) // 返回接口返回的错误信息
    }
  })
export default axios
