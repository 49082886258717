<template>
    <div class="content">
      <div class="card-box">
        <div class="btn-ct">
          <el-button type="primary" @click="func.newActivity" class="new-btn" size="small">新增公告</el-button>
        </div>
      </div>
      <div class="card-box data-table">
        <div class="header">
          <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
            }}</span>
          <div class="header-icons">
          </div>
        </div>
        <el-table :data="formData.tableData" :stripe="true" style="width: 100%">
          <el-table-column prop="Id" label="#" :width="100">
          </el-table-column>
          <el-table-column prop="Name" label="标题"></el-table-column>
          <el-table-column prop="AgentCode" label="运营商" :width="100" v-if="isAdmin()"></el-table-column>
          <el-table-column prop="EnableMode" label="状态" :width="80">
            <template #default="scope">
              <el-tag :type="scope.row.EnableMode ? 'success' : 'warning'">{{
                render.statuses[scope.row.EnableMode].label }}</el-tag></template>
          </el-table-column>
          <el-table-column prop="CreatedAtStr" label="开始时间" ></el-table-column>
          <el-table-column prop="UpdatedAtStr" label="结束时间" ></el-table-column>
          <el-table-column  :label="$t('g.operation')" fixed="right">
            <template #default="scope">
              <el-tag size="large" type="danger" @click="func.del(scope.row.Id)">删除</el-tag>
              <el-tag size="large" type="success" style="margin-left: 1rem;"
                @click="func.editDialog(scope.row)">修改</el-tag>
            </template>
          </el-table-column>
        </el-table>
  
      </div>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
          :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
        </el-pagination>
      </div>
      <el-dialog :title="formData.operateType == 1 ? '新增' : '编辑'" v-model="formData.addElDailog" width="80%" center>
        <div class="dailog-content">
          <div class="activity-content">
  
            <el-form label-width="180px" :rules="rules" :model="formData">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="公告标题" prop="name">
                    <el-input v-model="formData.Name" />
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="isAdmin()">
                  <el-form-item :label="$t('g.operator')" prop="name">
                    <!-- size="small" -->
                    <el-select class="inp"  v-model="formData.AgentCode" :placeholder="$t('g.choose')">
                      <el-option v-for="item in render.operatorList" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="公告时间" prop="startTime">
                    <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" 
                      type="daterange" unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
                      :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item :label="$t('member.status')">
                    <el-select class="inp" v-model="formData.EnableMode" :placeholder="$t('g.choose')">
                      <el-option v-for="item in render.statuses" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="是否跳转">
                    <el-select class="inp" v-model="formData.jumpType" :placeholder="$t('g.choose')">
                      <el-option v-for="item in render.jump" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="弹框次数">
                    <el-input v-model="formData.Poptimes" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="允许关闭弹框">
                    <el-select class="inp" v-model="formData.IsRemind" :placeholder="$t('g.choose')">
                      <el-option v-for="item in render.popup" :key="item.value" :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="公告内容" width="350px">
                    <el-input v-model="formData.Detail" :rows="7" type="textarea" placeholder="请输入公告内容" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="formData.addElDailog = false">取消</el-button>
            <el-button type="primary" @click="func.add">
              确认
            </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </template>
  <script>
  import { reactive, onMounted, computed } from 'vue'
  import activityStyle from '@/components/activityMode/activityStyle.vue'
  import { toDay } from '@/common/func'
  import i18n from '@/data/i18n/index'
  import { user, isOperator, isAdmin } from '@/data/token'
  import { shortcuts, operatorList } from '@/data/default'
  import Recharge from '../order/recharge.vue'
  import axios from "axios"
  import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'
  import { provinceAndCityData } from 'element-china-area-data'
  export default {
    name: 'activityManage',
    components: {
      activityStyle
    },
    setup() {
      const t = i18n.global.t
      const couponList = []
      const render = {
        shortcuts,
        couponList,
        statuses: [
          {
            label: t('device.disable'),
            value: 0
          },
          {
            label: t('device.enable'),
            value: 1
          }
        ],
        jump:[
          {
            label: '否',
            value: 0
          },
          {
            label: '跳转至拉新',
            value: 1
          },
          {
            label: '跳转至优惠券',
            value: 2
          }
        ],
        popup:[
          {
            label: '是',
            value: 1
          },
          {
            label: '否',
            value: 0
          }
        ],
        awardUnit: [
          {
            label: '分钟',
            value: 0
          },
          {
            label: '天',
            value: 0
          },
          {
            label: '元',
            value: 0
          }
        ],
        operatorList,
      }
      const rules = reactive({
      })
      const formData = reactive({
        tableData: [],
        curPage: 1,
        pageSize: 10,
        total: 0,
        jumpType:0,
        Poptimes:1,
        IsRemind:1
      })
      const func = {
        newActivity() {
          formData.operateType = 1
          formData.EnableMode = 1
          formData.addElDailog = true;
        },
        async search() {
          func.loadData()
        },
        pageChange(val) {
          console.log('page change', val)
          func.loadData(val)
        },
        editDialog(row) {
          formData.operateType = 2
          if (row) {
            formData.Id = row.Id
            formData.Name = row.Name
            formData.EnableMode = row.EnableMode
            formData.AgentCode = row.AgentCode
            formData.Detail = row.Detail
            formData.valTime = []
            if (row.StartTime) {
              formData.valTime.push(dayjs.unix(row.StartTime).format('YYYY-MM-DD'))
            }
            if (row.EndTime) {
              formData.valTime.push(dayjs.unix(row.EndTime).format('YYYY-MM-DD'))
            }
          }
          formData.addElDailog = true;
        },
        async del(Id) {
          ElMessageBox.confirm(t('g.sure_delete'), {
            confirmButtonText: t('confirm'),
            cancelButtonText: t('cancel'),
          }).then(() => {
            try {
              axios.post('/admin/notice/delete', {
                Id
              }).then((res) => {
                if (res == '操作成功') {
                  ElMessage({
                    type: 'success',
                    message: t('g.success')
                  })
                } else {
                  ElMessage({
                    type: 'error',
                    message: t("err_code.20005")
                  })
                }
                func.loadData()
              })
  
            } catch (error) {
              ElMessage({
                type: 'error',
                message: t("err_code.20005")
              })
            }
  
          })
        },
        async add(id) {
          let params = {
            EnableMode:formData.EnableMode
          }
          if (formData.Name) {
            params.Name = formData.Name
          }
          if (formData.AgentCode) {
            params.AgentCode = formData.AgentCode
          }
  
          if (formData.ReMark) {
            params.Remark = formData.ReMark
          }
          if (formData.valTime) {
            params.StartTime = Math.floor(new Date(formData.valTime[0]).getTime() / 1000)
            params.EndTime = Math.floor(new Date(formData.valTime[1] + " 23:59:59").getTime() / 1000)
          }
          if (formData.Detail) {
            params.Detail = formData.Detail
          }
          let res
          if (formData.operateType == 2) {
            params.Id = formData.Id
            console.log(params)
            //return
            res = await axios.post('/admin/notice/edit', params);
          } else {
            res = await axios.post('/admin/notice/add', params);
          }
          console.log(JSON.stringify(res));
  
          if (res) {
            formData.addElDailog = false;
            func.loadData()
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
          } else {
            ElMessage({
              type: 'error',
              message: t('err_code.20017')
            })
          }
  
        },
        async loadData(page = 1) {
          let params = {
            page,
          }
          formData.curPage = page
          // if (formData.id) {
          //   params.id = formData.id
          // }
          let res = await axios.get('/admin/notice/get', {
            params,
          })
          console.log(res,'列表参数')
          if (res) {
            formData.total = res.Total
            res.List.map((item) => {
              item.CreatedAtStr = toDay(item.StartTime)
              item.UpdatedAtStr = toDay(item.EndTime)
            })
            formData.pageSize = res.PageSize
            formData.tableData = res.List
          }
        },
      }
      onMounted(() => {
        if (provinceAndCityData[0].value != 0) {
          provinceAndCityData.unshift({
            value: "0",
            label: "全部"
          })
        }
        //func.getCoupon()
        func.loadData()
        // func.loadData()
      })
      return {
        formData,
        func,
        formData,
        render,
        token: user.token,
        isOperator,
        isAdmin,
        rules,
        provinceAndCityData,
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .content {
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
  
    >.card-box {
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
  
      .header {
        text-align: left;
        margin-bottom: 1.25rem;
        position: relative;
      }
  
      .inp-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
  
        >.item {
          width: 49%;
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
  
          .label {
            margin-right: 0.625rem;
            width: 4.375rem;
          }
  
          :deep(.el-range-editor--small.el-input__inner) {
            flex: 1;
          }
  
          .inp {
            flex: 1;
          }
        }
      }
  
      >.btn-ct {
        text-align: right;
  
        :deep(.el-button--small) {
          padding: 0.625rem 2rem;
        }
      }
  
      >.pagination {
        text-align: right;
        margin-top: 1.25rem;
      }
  
    }
  
    .new-el-dialog {
      .dailog-content {
        display: flex;
      }
  
      .activity-content {
        flex: 2
      }
  
      // .activity-style {
      //   flex: 1;
      // }
  
  
    }
  
    :deep(.m-dialog) {
      // max-width: 80rem;
      margin-top: 0;
    }
  }
  </style>