<template>
  <div class="component">
    <div class="chart-box">
      <div :id="render.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'

export default {
  name: 'PropertiesStatusRadio',
  props: {
    agentCode:[String,Number],
    id: { // 唯一标识，用于图表渲染，防止重复
      type: String,
      default: ''
    }
  },
  setup (props, ctx) {
    const t = i18n.global.t
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    let myChart = null
    const id = 'chartid-properties-status-ratio' + props.id
    const render = {
      id
    }
    const formData = reactive({
    })
    const initChart = () => {
      const chartDom = document.getElementById(render.id)
      myChart = echarts.init(chartDom)
      loadData()
    }
    const loadData = async () => {
      const params = {
        agentCode:props.agentCode || "",
      }
      let text = ""
      switch(props.id){
        case "ebox":
          text = t('chart.devs_status.ebox')
          params.devType = 1
          break
        case "cbox":
          text = t('chart.devs_status.cbox')
          params.devType = 2
          break
        case "pile":
          text = t('chart.devs_status.pile')
          params.devType = 3
          break
        case "cpbox":
          text = t('chart.devs_status.cpbox')
          params.devType = 4
          break
      }
      let res = await axios.get("/admin/analyze/prop/status/dev",{
        params
      })
      const data = []
      for(let key in res){
        data.push({
          name:t('chart.keys.'+key),
          value:res[key],
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        })
      }
      const legend = {
        bottom: '0%',
        left: 'center',
        textStyle:{
          fontSize:16,
        }
      }
      const option = {
        title: {
          text,
        },
        tooltip: {
          trigger: 'item'
        },
        legend,
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: t('chart.num'),
            type: 'pie',
            label: {
              formatter: '{b}:{c}({d})%',
              fontSize:16,
            },
            data
          }
        ]
      }

      option && myChart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      formData,
      render
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
