<template>
  <div class="content">
    <div class="card-box data-table">
      <div class="header">
        <div class="header-icons">
          <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
		  <el-icon @click="func.add()" class="header-icon"><Plus /></el-icon>
        </div>
      </div>
      <el-table
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="#"
              width="80">
            </el-table-column>
            <el-table-column
              prop="Title"
              :label="$t('rights.name')"
              width="200">
            </el-table-column>
            <el-table-column
              prop="Description"
              :label="$t('rights.description')"
              min-width="200">
            </el-table-column>
            <el-table-column
              width="120"
              :label="$t('expenses.status')">
              <template #default="scope">
                <el-tag
                    :type="scope.row.Status==1?'success':'info'"
                    effect="dark">
                    {{ scope.row.Status==1?$t('expenses.statuses.enabled'):$t('expenses.statuses.disabled') }}
                  </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              width="220"
              :label="$t('g.operation')">
              <template #default="scope">
                <el-tag
                  @click="func.showRights(scope.row)"
                  type="success"
                >{{$t('rights.rights')}}</el-tag>
                <el-tag
                  class="btn-del"
                  @click="func.edit(scope.row)"
                >{{$t('g.edit')}}</el-tag>
                <el-tag
                  class="btn-del"
                  @click="func.del(scope.row)"
                  type="warning"
                >{{$t('g.delete')}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <el-dialog
      :title="$t('tips')"
      v-model="formData.dialogVisible"
      width="60%">
      <el-form  label-width="80px" :model="formData.curData">
        <div class="el-form-row">
          <el-form-item :label="$t('rights.name')">
            <el-input v-model="formData.curData.Title"></el-input>
          </el-form-item>
          <el-form-item :label="$t('rights.description')">
            <el-input v-model="formData.curData.Description"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogVisible = false">{{$t('g.btn_cancel')}}</el-button>
          <el-button type="primary" @click="func.save()">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :title="$t('tips')"
      v-model="formData.rightsDialogVisible"
      width="60%">
      <div class="cbs">
        <div class="item" v-for="(item,index) in formData.rights" :key="index">
          <el-checkbox @change="func.topChange(item)" class="cb" v-model="item.Checked" :label="item.ModuleName"></el-checkbox>
          <div class="sub" v-for="(item2,index) in item.AccessItem" :key="index">
            <el-checkbox @change="func.subChange(item,item2)" class="cb" v-model="item2.Checked" :label="item2.ModuleName"></el-checkbox>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.rightsDialogVisible = false">{{$t('g.btn_cancel')}}</el-button>
          <el-button type="primary" @click="func.setRights()">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from "@/data/default"
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'RightsRole',
  setup () {
    const t  = i18n.global.t
    const tableData = [
    ]
    const render = {
      shortcuts
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valDevId: '',
      valName: '',
      dialogVisible: false,
      rightsDialogVisible: false,
      curPage: 1,
      rights:[],
      pageSize: 10,
      total: 0,
      valAddress: ''
    })
    const func = {
      search () {
        console.log('formData', formData)
        func.loadData()
      },
      edit(row){
        formData.curData = Object.assign({},row)
        formData.dialogVisible = true
      },
      topChange(row){
        for(let item of row.AccessItem){
          item.Checked = row.Checked
        }
      },
      subChange(top,row){
        if(row.Checked){
          top.Checked = true
        }
      },
      async showRights(row){
        formData.curData = Object.assign({},row)
        let res = await axios.get('/admin/role/auth?id='+row.Id)
        let rights = []
        for(let item of res){
          let tmp = {
            Id:item.Id,
            ModuleName:item.ModuleName,
            Checked:item.Checked,
          }
          if(item.AccessItem){
            tmp.AccessItem = []
            for(let item2 of item.AccessItem){
              tmp.AccessItem.push({
                Id:item2.Id,
                ModuleName:item2.ModuleName,
                Checked:item2.Checked,
              })
            }
          }
          rights.push(tmp)
        }
        formData.rights = rights
        formData.rightsDialogVisible = true
      },
      async setRights(){
        let rights = ""
        for(let top of formData.rights){
          if(top.Checked)rights+=","+top.Id;
          for(let sub of top.AccessItem){
            if(sub.Checked)rights+=","+sub.Id;
          }
        }
        if(rights){
          rights = rights.substr(1)
        }
        await axios.post('/admin/role/doAuth',{
          role_id:formData.curData.Id,
          access_node:rights,
        })
        formData.rightsDialogVisible = false
      },
      async del(row){
        try{
          await ElMessageBox.confirm(
              t('g.sure_delete'),
              t('tips'),
              {
                confirmButtonText: t('confirm'),
                cancelButtonText: t('cancel'),
                type: 'warning',
              }
            )
          await axios.post("/admin/role/delete",{
            id:row.Id
          })
          func.loadData()
        }catch(e){
          console.log("del",e)
        }
      },
      async save(){
        const data = formData.curData
        let params = {
          title:data.Title,
          description:data.Description
        }
        if(data.Id){
          params.id = data.Id
          await axios.post("/admin/role/doEdit",params)
        }else{
          await axios.post("/admin/role/doAdd",params)
        }
        func.loadData()
        formData.dialogVisible = false
      },
      add(){
        formData.curData = {}
        formData.dialogVisible = true
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1){
        try{
          formData.tableData = []
          formData.curPage = page
          let res = await axios.get(`/admin/role`,{
            params:{}
          })
          formData.total = res.Total
          formData.tableData = res
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(()=>{
      func.loadData()
    })
    return {
      func,
      render,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .inp2{
    width: 100%;
  }
  .btn-del{
    margin-left: 0.5rem;
  }
  .cbs{
    .cb{
    }
    >.item{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      >.cb{
        width: 100%;
        text-align: left;
        margin-bottom: 0.5rem;
      }
      >.sub{
        margin-left: 1rem;
      }
    }
  }
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: normal;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  :deep(.m-dialog){
    max-width: 50rem;
  }
}
@media screen and (max-width:800px) {
    .content{
      >.card-box{
        .inp-list{
          >.item{
            width: 100%;
          }
        }
      }
    }
}
</style>
