import { reactive } from 'vue'
import storage from "@/common/storage"
import { loadUserMenu } from './menu'
import { OPERATOR_ROLE_ID,SITE_MANAGER_ROLE_ID,ADMIN_ROLE_ID } from "./default"
const key = "dandelion-token"
export const user = reactive({
  name:"",
  id:"",
  token:"",
  type:"",
  roleId:0,
  isMch:0,
})

export const isOperator = ()=>{
  return user.type == 'operator' || user.roleId == SITE_MANAGER_ROLE_ID
}


export const isAdmin = ()=>{
  return user.type == 'admin'
}

export const setUser = (token,id,name,roleId,isMch,save = true)=>{
  user.token = token
  user.id = id
  user.name = name
  user.isMch = isMch
  user.roleId = roleId
  if(roleId == OPERATOR_ROLE_ID){
    user.type = "operator"
  }else if(roleId == ADMIN_ROLE_ID){
    user.type = "admin"
  }
  if(save)storage.set(key,user)
  loadUserMenu(user.id)
}


//load user
const userData = storage.get(key)
if(userData){
  setUser(userData.token,userData.id,userData.name,userData.roleId,userData.isMch,false)
}
// console.log(JSON.stringify(user));