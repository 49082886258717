<template>
  <div class="content">
    <div class="avatar-box">
      <div class="name">{{formData.profile.Name}}</div>
      <img class="avatar" src="@/assets/logo.png" />
    </div>
    <div class="card-box">
      <el-tabs v-model="formData.activeName">
        <el-tab-pane :label="$t('member.detail')" name="first">
          <div class="tab-ct inp-list">
            <div class="item">
              <span class="label">{{$t('member.nickname')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.Name" disabled/>
            </div>
            <div class="item">
              <span class="label">{{$t('member.username')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.Username" disabled/>
            </div>
            <div class="item" v-if="formData.profile.AgentInfo.Mchid">
              <span class="label">{{$t('member.mchid')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.AgentInfo.Mchid" disabled/>
            </div>
            <div class="item" v-if="formData.profile.AgentInfo.Code">
              <span class="label">{{$t('g.agent_code')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.AgentInfo.Code" disabled/>
            </div>
            <div class="item">
              <span class="label">{{$t('g.create_at')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.CreateAtStr" disabled/>
            </div>
            <div class="item" v-if="formData.profile.AgentInfo.Name">
              <span class="label">{{$t('member.agent_name2')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.AgentInfo.Name" disabled/>
            </div>
            <div class="item" v-if="formData.profile.AgentInfo.ShortName">
              <span class="label">{{$t('member.agent_info')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.AgentInfo.ShortName" disabled/>
            </div>
            <div class="item" v-if="formData.profile.AgentInfo.Id">
              <span class="label">{{$t('member.wechat_rate')}}</span>
              <el-input class="inp" size="small" v-model="formData.profile.AgentInfo.WxRate" disabled/>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane :label="$t('member.modify_password')" name="second">
          <div class="tab-ct inp-list">
            <div class="item">
              <span class="label">{{$t('member.new_password')}}</span>
              <el-input type="password" :placeholder="$t('member.new_password_ph')" class="inp" size="small" v-model="formData.password"/>
            </div>
            <div class="item">
              <span class="label">{{$t('member.repassword')}}</span>
              <el-input type="password" :placeholder="$t('member.repassword_ph')" class="inp" size="small" v-model="formData.repassword"/>
            </div>
          </div>
          <div class="footer">
            <el-button @click="func.modifyPassword" class="confirm" type="primary" size="small">{{$t("confirm")}}</el-button>
          </div>
        </el-tab-pane>

      </el-tabs>
    </div>
  </div>
</template>

<script>
import { reactive,onMounted } from 'vue'
import { toDay } from '@/common/func'
import { ElMessage } from 'element-plus'
import i18n from '@/data/i18n/index'
import axios from 'axios'

export default {
  name: 'MemberProfile',
  setup () {
    const t  = i18n.global.t
    const formData = reactive({
      activeName: 'first',
      password:"",
      repassword:"",
      profile: {
        AgentInfo:{}
      },
    })
    const func = {
      async modifyPassword(){
        if(!formData.password.trim()){
          return ElMessage({
            type: 'error',
            message: t('member.new_password_ph')
          })
        }
        if(formData.password != formData.repassword){
          return ElMessage({
            type: 'error',
            message: t('member.repassword_not_same')
          })
        }
        let params = {
          password : formData.password
        }
        try{
          await axios.post("/admin/manager/editPassword",params)
          formData.password = ""
          formData.repassword = ""
          return ElMessage({
            message:t("g.success"),
            type:'success'
          })
        }catch(e){

        }
      }
    }
    const loadData = async ()=>{
      // console.log("loadData")
      let res = await axios.get("/admin/userDetail")
      res.CreateAtStr = toDay(res.CreateTime)
      if(res.AgentInfo.WxRate){
        res.AgentInfo.WxRate *= 100
      }
      formData.profile = res
      // console.log("res",res)
    }
    onMounted(()=>{
      loadData()
    })
    return {
      formData,
      func
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  .avatar-box{
    background: url(../../assets/img/cover.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 23.125rem;
    overflow: hidden;
    position: relative;
    border-radius: 0.25rem;
    box-shadow: $box-shadow;
    margin-bottom:1.25rem;
    >.name{
      background-color: $color-bg;
      padding: 0.625rem 0;
      margin-top: 15rem;
      text-align: left;
      padding-left: 17rem;
      font-size: 1.375rem;
    }
    >.avatar{
      width: 11.25rem;
      height: 11.25rem;
      border-radius: 50%;
      border: 0.375rem solid $color-bg;
      background-color: $color-bg;
      position: absolute;
      bottom: 0.625rem;
      left: 3rem;
    }
  }
  .card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .footer{
      text-align: right;
    }
    .tab-ct{
      margin-top: 1.3rem;
      text-align: left;
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1.3rem;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
}
</style>
