<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t("g.condition")}}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{$t("log.trigger_time")}}</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                value-format="YYYY-MM-DD"
                size="small"
                type="daterange"
                unlink-panels
                :range-separator="$t('g.to')"
                :start-placeholder="$t('g.start_date')"
                :end-placeholder="$t('g.end_date')"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{$t("device.no")}}</span>
          <el-input class="inp" size="small" v-model="formData.valDevId" :placeholder="$t('device.no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{$t("log.name_contain")}}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('log.input_name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{$t("log.address_contain")}}</span>
          <el-input class="inp" size="small" v-model="formData.valAddress" :placeholder="$t('log.input_address')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
      </div>
      <el-table
            @row-click="func.rowDblClick"
            :data="formData.tableData"
            style="width: 100%"
            >
            <el-table-column
              prop="Id"
              label="#"
              width="100">
            </el-table-column>
            <el-table-column
              prop="CreatedAtStr"
              :label="$t('log.trigger_time')"
              width="180">
            </el-table-column>
            <el-table-column
              prop="DeviceName"
              width="160"
              :label="$t('device.name')">
            </el-table-column>
            <el-table-column
              prop="DevId"
              width="240"
              :label="$t('device.no')">
            </el-table-column>
            <!-- <el-table-column
              prop="TypeStr"
              width="120"
              :label="$t('log.type')">
              <template #default="scope">
                  {{ alertType[scope.row.Type] }}
              </template>
            </el-table-column> -->
            <el-table-column
              prop="TypeStr"
              width="120"
              :label="$t('log.type')">
            </el-table-column>
            <el-table-column
              prop="log.type_txt"
              width="160"
              :label="$t('log.info')">
            </el-table-column>
            <el-table-column
              prop="DeviceAddress"
              min-width="300"
              :label="$t('device.address')">
            </el-table-column>
            <el-table-column
              prop="logStr"
              width="350"
              show-overflow-tooltip
              :label="$t('log.info_detail')">
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :total="formData.total">
      </el-pagination>
    </div>

    <el-dialog
      :title="$t('tips')"
      v-model="formData.dialogVisible"
      width="80%">
      <el-descriptions border>
      <el-descriptions-item :label="$t('device.no')">{{ formData.curData.DevId }}</el-descriptions-item>
      <el-descriptions-item :label="$t('device.name')">{{ formData.curData.DeviceName }}</el-descriptions-item>
      <el-descriptions-item :label="$t('log.type')">{{ formData.curData.TypeStr }}</el-descriptions-item>
      <el-descriptions-item :label="$t('log.trigger_time')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
      <el-descriptions-item :label="$t('log.info')">{{ formData.curData.log.type_txt }}</el-descriptions-item>
      <el-descriptions-item :label="$t('device.address')">{{ formData.curData.DeviceAddress }}</el-descriptions-item>
      <el-descriptions-item :label="$t('log.info_detail')">{{ formData.curData.logStr }}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="formData.dialogVisible = false">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,onMounted } from 'vue'
import axios from 'axios'
import { shortcuts } from "@/data/default"
import { toDay } from "@/common/func"
import i18n from '@/data/i18n/index'

export default {
  name: 'LogSystem',
  setup () {
    const t  = i18n.global.t
    const tableData = [
    ]
    const render = {
      shortcuts
    }
    const alertType = [t('log.types.t0'),t('log.types.t1'),t('log.types.t2'),t('log.types.t3'),t('log.types.t4'),t('log.types.t5')]
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valDevId: '',
      valName: '',
      dialogVisible: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      valAddress: ''
    })
    const func = {
      search () {
        console.log('formData', formData)
        func.loadData()
      },
      rowDblClick (row, column, e) {
        console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      async loadData(page = 1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valDevId){
            params.devId = formData.valDevId
          }
          if(formData.valName){
            params.name = formData.valName
          }
          if(formData.valAddress){
            params.address = formData.valAddress
          }
          if(formData.valTime){
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/log/dev`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            // console.log(JSON.stringify(item));
            item.logStr = item.Log
            if(item.Log.indexOf('mqtt掉线') != -1 ){
              item.log = {'type_txt':item.Log};
            }else{
              
              item.log = (item.Log && item.Log != "null")? JSON.parse(item.Log) : ""
            }
            
            item.CreatedAtStr = toDay(item.CreateAt)
            item.TypeStr = ""
            item.TypeStr = t("log.types.t"+item.Type)
          })
          // console.log(JSON.stringify(formData.tableData));
          
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(()=>{
      func.loadData()
      axios.post('/admin/log/dev/read',{})
    })
    return {
      func,
      render,
      formData,
      alertType
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: normal;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
  }
  :deep(.m-dialog){
    max-width: 50rem;
  }
}
@media screen and (max-width:800px) {
    .content{
      >.card-box{
        .inp-list{
          >.item{
            width: 100%;
          }
        }
      }
    }
}
</style>
