import { reactive } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'
const t = i18n.global.t
export const getEqs = async (isMarket = false) => {
  try{
    let url = "/admin/box?getAll=1"
    if(isMarket){
      url += "&isMarket=1"
    }
    let res = await axios.get(url)
    let data = []
    for (const item of res.List) {
      if(item.Latitude==0&&item.Longitude==0){
        continue;
      }
      let imgs = []
      if(item.ImgBox)imgs.push(item.ImgBox);
      if(item.ImgStore)imgs.push(item.ImgStore);
      if(item.ImgStreet)imgs.push(item.ImgStreet);
      let obj = {
        id:item.Id,
        // lat:Math.round(item.Latitude*1000000)/1000000,
        // lng:Math.round(item.Longitude*1000000)/1000000,
        lat:item.Latitude,
        lng:item.Longitude,
        type:item.Type,
        name:item.Name,
        saveMoney:item.SaveMoney,
        chargeMoney:item.ChargeMoney,
        status:(item.Status == 1 && item.Alert === 0)?t('device.statuses.online'):t('device.statuses.offline'),
        address:item.Address || t('common.out_of_sync'),
        devId:item.DevId,
        agentCode:item.AgentCode,
        siteName:item.SiteName,
        slotNum:item.SlotNum,
        slotNumErr:item.SlotNumErr,
        slotNumEm:item.SlotNumEm,
        deposit:item.Deposit,
        imgs,
        tel:"",
        contacts:"",
      }
      if(isMarket){
        obj.devUseFrequency = item.DevUseFrequency
        obj.dayOrderNum = item.DayOrderNum
        obj.weekOrderNum = item.WeekOrderNum
        obj.monthOrderNum = item.MonthOrderNum
      }
      data.push(obj)
    }
    return data
  }catch(e){

  }
}
// 充电桩
// 电池
// 车辆
