<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t('g.condition')}}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{$t("g.create_at")}}</span>
          <el-date-picker
                class="inp"
                v-model="formData.valTime"
                value-format="YYYY-MM-DD"
                size="small"
                type="daterange"
                unlink-panels
                :range-separator="$t('g.to')"
                :start-placeholder="$t('g.start_date')"
                :end-placeholder="$t('g.end_date')"
                :shortcuts="render.shortcuts"
              >
              </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{$t('expenses.type')}}</span>
          <el-select class="inp" size="small" v-model="formData.valType" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.types"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('device.status')}}</span>
          <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.statuses"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item" v-if="!isOperator()">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
        <div class="header-icons">
          <el-icon @click="func.showAddDataDialog()" class="header-icon"><Plus /></el-icon>
        </div>
      </div>
      <el-table
            @row-click.self="func.showDetails"
            :data="formData.tableData"
            style="width: 100%">
            <el-table-column
              prop="Id"
              label="#"
              width="60">
            </el-table-column>
            <el-table-column
              prop="Name"
              :label="$t('expenses.name')"
              width="140">
            </el-table-column>
            <el-table-column
              prop="AgentCode"
              :label="$t('g.agent_code')"
               v-if="!isOperator()"
              width="140">
            </el-table-column>
            <el-table-column
              width="80"
              :label="$t('expenses.type')">
              <template #default="scope">
                <div>{{scope.row.Type==1?$t("expenses.types.by_month"):$t("expenses.types.by_times")}}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="Num"
              :label="$t('expenses.num')"
              width="80">
            </el-table-column>
            <el-table-column
              prop="Price"
              :label="$t('expenses.price')"
              width="120">
            </el-table-column>
            <el-table-column
              prop="DaysOfTimes"
              :label="$t('member.expired_day')"
              width="120">
            </el-table-column>
            <el-table-column
              prop="DevType"
              :label="$t('device.type')"
              width="140" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
              width="120"
              :label="$t('expenses.status')">
              <template #default="scope">
                <el-tag
                    :type="scope.row.Status==1?'success':'info'"
                    effect="dark">
                    {{ scope.row.Status==1?$t('expenses.statuses.enabled'):$t('expenses.statuses.disabled') }}
                  </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="CreateAtStr"
              min-width="180"
              :label="$t('g.create_at')">
            </el-table-column>
            <el-table-column
              width="120"
              :label="$t('g.operation')"
              fixed="right"
              
            >
              <template #default="scope">
                <el-tag
                  @click="func.edit(scope.row)"
                >{{$t('g.edit')}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="func.pageChange"
        :page-size="formData.pageSize"
        :current-page="formData.curPage"
        :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible"  width="80%">
      <el-descriptions border>
        <el-descriptions-item label="#">{{ formData.curData.Id }}</el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.name')">{{ formData.curData.Name }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.type')">
                {{formData.curData.Type==1?$t("expenses.types.by_month"):$t("expenses.types.by_times")}}
              </el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.num')">{{ formData.curData.Num }}</el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.price')">{{ formData.curData.Price }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.expired_day')" v-if="formData.curData.Type==2">{{ formData.curData.DaysOfTimes }}</el-descriptions-item>
        <el-descriptions-item :label="$t('expenses.status')">  <el-tag
                    :type="formData.curData.Status==1?'success':'info'"
                    effect="dark">
                    {{ formData.curData.Status==1?$t('expenses.statuses.enabled'):$t('expenses.statuses.disabled') }}
                  </el-tag></el-descriptions-item>
          <el-descriptions-item :label="$t('device.type')">{{ formData.curData.DevType }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
      
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :title="$t('g.detail')"
      v-model="formData.dialogVisible"
      width="70%">
      <el-form  label-width="180px" :model="formData.curData">
        <div class="el-form-row">
        <el-form-item :label="$t('expenses.name')">
            <el-input size="small" v-model="formData.curData.Name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('expenses.status')">
            <el-select class="inp" size="small" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.updateStatuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        
        </div>
        <div class="el-form-row">
          <el-form-item :label="$t('expenses.price')">
            <el-input size="small" type="number" v-model="formData.curData.Price"></el-input>
          </el-form-item>
          <el-form-item :label="$t('expenses.type')">
            <el-select class="inp" size="small" v-model="formData.curData.Type" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.updateTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        
        </div>
        <div class="el-form-row" >
          <el-form-item :label="$t('expenses.num')+'('+(formData.curData.Type==1?$t('expenses.types.by_month'):$t('expenses.types.by_times'))+')'">
            <el-input size="small" type="number" v-model="formData.curData.Num"></el-input>
          </el-form-item>
          <el-form-item :label="$t('member.expired_day')" v-show="formData.curData.Type==2">
            <el-input size="small" type="number" v-model="formData.curData.DaysOfTimes"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row" >
          <el-form-item :label="$t('g.agent_code')" v-if="!formData.curData.Id && render.updateOperatorsList.length>1">
            <el-select class="inp" size="small" v-model="formData.curData.AgentCode" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.updateOperatorsList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.type')">
            <el-select v-model="formData.curData.DevTypeStr" placeholder="Select"  multiple>
              <el-option
              v-for="item in formData.devType"
              :key="item"
              :label="item"
              :value="item"
            >
           </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{$t('g.btn_confirm')}}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive,onMounted,computed,watch } from 'vue'
import { operators, updateOperators,shortcuts,operatorList } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import { ElMessage } from 'element-plus'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'ExpensesCombo',
  setup () {
    const t  = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('expenses.statuses.all'),
        value: 0
      },
      {
        label: t('expenses.statuses.enabled'),
        value: 2
      },
      {
        label: t('expenses.statuses.disabled'),
        value: 1
      }
    ]
    const types = [
      {
        label: t('expenses.types.all'),
        value: 0
      },
      {
        label: t('expenses.types.by_month'),
        value: 1
      },
      {
        label: t('expenses.types.by_times'),
        value: 2
      }
    ]

    const render = {
      operators,
      statuses,
      types,
      updateTypes: [
        {
          label: t('expenses.types.by_month'),
          value: 1
        },
        {
          label: t('expenses.types.by_times'),
          value: 2
        }
      ],
      updateOperatorsList:operatorList,
      updateStatuses: [
        {
          label: t('expenses.enable'),
          value: 1
        },
        {
          label: t('expenses.disable'),
          value: 0
        }
      ],
      shortcuts
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      valStatus: 0,
      valType: 0,
      dialogVisible: false,
      dialogDisplayVisible:false,
      curPage: 1,
      pageSize: 10,
      total: 500,
      valOperator: 0,
      devType:[]
    })
    watch(()=>formData.curData.AgentCode,()=>{
      func.getDevType(formData.curData.AgentCode)
    })
    const func = {
      search () {
        console.log('formData', formData)
        func.loadData()
      },
      async getDevType(agent){
        let params = {}
        params.agentCode = agent
        params.getAll = 1
        let res = await axios.get('/admin/box/devtypes', { params })
        if(res){
          formData.devType = res
        }
      },
      async showDetails(row,column){
        if(column&&column.label==t('g.operation')){
          return;
        }
        formData.curData = row;
        formData.dialogDisplayVisible = true;
      },
      edit (row, column, e) {
        formData.curData = row
        if(formData.curData.DevType){
          formData.curData.DevTypeStr = formData.curData.DevType.split('|')
        }
        formData.dialogVisible = true
      },
      pageChange (val) {
        console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog(){
        formData.curData = {
          AgentCode:operatorList.length?operatorList[0].value:"",
        }
        formData.dialogVisible = true 
      },
      async updateData () {
        // console.log("updateOrCreate Data",formData.curData)
        if(typeof(formData.curData.Type) == 'undefined'){
          ElMessage({
            type:'error',
            message:t('expenses.tips.type')
          })
          return
        }
        if(typeof(formData.curData.Status) == 'undefined'){
          ElMessage({
            type:'error',
            message:t('expenses.tips.staus')
          })
          return
        }
        if(typeof(formData.curData.Num) == 'undefined'){
          ElMessage({
            type:'error',
            message:t('expenses.tips.num')
          })
          return
        }
        if(typeof(formData.curData.Price) == 'undefined'){
          ElMessage({
            type:'error',
            message:t('expenses.tips.price')
          })
          return
        }
        if(typeof(formData.curData.AgentCode) == 'undefined' && !fromData.curData.Id){
          ElMessage({
            type:'error',
            message:t('expenses.tips.agent_code')
          })
          return
        }
        
        try{
          if(formData.curData.Price < 0){
            ElMessage({
              type:'error',
              message:t('expenses.tips.correct_price')
            })
            return
          }
          if(formData.curData.Num < 0){
            ElMessage({
              type:'error',
              message:t('expenses.tips.correct_num')
            })
            return
          }
          let params = {
              name:formData.curData.Name,
              type:formData.curData.Type,
              price:formData.curData.Price,
              num:formData.curData.Num,
              status:formData.curData.Status,
            }
            if(formData.curData.DaysOfTimes&&formData.curData.Type==2){
              params.daysOfTimes = formData.curData.DaysOfTimes
            }
            if(formData.curData.DevTypeStr&&formData.curData.DevTypeStr.length>0){
              params.devType = formData.curData.DevTypeStr.join('|') 
            }
          if(formData.curData.Id){
            params.id = formData.curData.Id
            await axios.put('/admin/combo',params)
          }else{
             if(formData.curData.AgentCode){
              params.agentCode=formData.curData.AgentCode
            }
            await axios.post('/admin/combo',params)
          }
        }catch(e){

        }
        formData.dialogVisible = false
        func.loadData()
      },
      async loadData(page=1){
        try{
          formData.tableData = []
          formData.curPage = page
          let params = {
              page,
          }
          if(formData.valStatus){
            params.status = formData.valStatus
          }
          if(formData.valOperator){
            params.agentCode = formData.valOperator
          }
          if(formData.valType){
            params.type = formData.valType
          }
          if(formData.valTime){
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
          }
          let res = await axios.get(`/admin/combo`,{
            params
          })
          formData.total = res.Total
          res.List.map((item)=>{
            item.CreateAtStr = toDay(item.CreateAt)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        }catch(e){
          console.log("loadData",e)
        }
      }
    }
    onMounted(()=>{
      func.loadData()
    })
    return {
      func,
      render,
      isOperator,
      formData
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;
  >.card-box{
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;
    .header{
      text-align:left;
      margin-bottom: 1.25rem;
      position: relative;
      >.title{
        font-size:1rem;
        font-weight: 500;
      }
      >.header-icons{
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;
        .header-icon{
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color:#969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }
    >.btn-ct{
      text-align: right;
      :deep(.el-button--small){
        padding: 0.625rem 2rem;
      }
    }
    .inp-list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      >.item{
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;
        .label{
          margin-right: 0.625rem;
          width: 4.375rem;
        }
        :deep(.el-range-editor--small.el-input__inner){
          flex: 1;
        }
        .inp{
          flex: 1;
        }
      }
    }
  }
  >.el-alert-my{
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;
    .el-alert-my-txt{
      font-size: 0.875rem;
      >.tips{
        font-weight: bold;
      }
    }
    :deep(.el-alert__closebtn){
      top: 1.25rem;
      font-size: 1rem;
    }
  }
  >.data-table{
    :deep(.el-table){
      .cell{
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .el-table__row{
        cursor: pointer;
      }
    }
  }
  >.pagination{
    text-align: right;
    margin-top: 1.25rem;
  }
  .el-form-row{
    display:flex;
    justify-content: space-between;
    :deep(.el-form-item){
      width: 49%;
    }
    .inp{
      width: 100%;
    }
  }
  .el-select-my{
    text-align: left;
    display: block;
  }
  :deep(.m-dialog){
    max-width: 50rem;
  }
}
</style>
