<template>
  <div class="component" v-show="showMainMenu" :style="{width:isEnVersion.status?'15.5rem':'10rem'}">
    <el-collapse class="collapse" v-model="activeName" accordion>
      <div class="collapse-group" v-for="(item,idx) in userMenuList.data" :key="idx" >
        <div class="collapse-group-name">{{item.title}}</div>
        <el-collapse-item :key="idx2" v-for="(item2,idx2) in item.item" :name="idx*10+idx2">
          <template #title>
              <el-icon class="header-icon"><Timer /></el-icon>
              <span>{{item2.title}}</span>
          </template>
          <div @click="navTo(item3)" class="nav-item" v-for="(item3,idx3) in item2.item" :key="idx3">{{item3.title}}</div>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>
</template>

<script>
import { ref,reactive,onMounted } from 'vue'
import { navTo } from '@/common/func'
import { showMainMenu, toggleMainMenu, isMiniScreen } from '@/data/default'
import { userMenuList } from '@/data/menu'
import storage from '@/common/storage'
export default {
  name: 'PublicLeft',
  setup () {
    const activeName = ref('0')
    const isEnVersion = reactive({
      status: false,
    })
    onMounted(() => {
      if (storage.get('locale') == 'En') {
        isEnVersion.status = true;
      }
    })
    return {
      userMenuList,
      navTo: (item) => {
        if (isMiniScreen.value) {
          toggleMainMenu()
        }
        navTo(item.name)
      },
      showMainMenu,
      activeName,
      isEnVersion
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    // width: 15vw;
    width: 14.75rem;
    padding: 0 1.875rem;
    //样式穿透
    :deep(.collapse){
      &.el-collapse{
        border: 0;
        margin-top: -1.25rem;
      }
      .collapse-group-name{
        text-align: left;
        font-size: 0.8125rem;
        color: #969696;
        margin-top: 2.5rem;
      }
      .el-collapse-item__header{
        background-color: transparent;
        font-size: 0.9rem;
        font-weight: bold;
        border: 0;
        .header-icon{
          font-size: 1.3rem;
          margin-right: 0.625rem;
          font-weight: bold;
        }
      }
      .el-collapse-item__wrap{
        background-color: transparent;
        .el-collapse-item__content{
          border-left: 2px solid #999;
          padding-left: 1rem;
          margin-bottom: 0.3125rem;
          padding-bottom: 0;
          margin-left: 0.625rem;
        }
        .nav-item{
          font-size: 0.9rem;
          text-align: left;
          padding: 0.375rem 0;
          cursor: pointer;
        }
      }
    }
  }
</style>
