<template>
  <div class="component">
    <div class="chart-box">
      <div :id="render.id" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { onMounted, reactive, watch } from 'vue'
import axios from 'axios'
import i18n from '@/data/i18n/index'
export default {
  name: 'OrderRatio',
  props: {
    id: { // 唯一标识，用于图表渲染，防止重复
      type: String,
      default: ''
    },
    agentCode:[String,Number],
    cycle:{
      type:Number,
      default:1,
    },
    startAt:{
      type:Date,
      default:null
    }
  },
  setup (props, ctx) {
    const t = i18n.global.t
    let myChart = null
    const id = 'chartid-order-ratio' + props.id
    const render = {
      id
    }
    watch(()=>props,()=>{
      loadData()
    },{deep:true})

    const formData = reactive({
    })
    const initChart = () => {
      const chartDom = document.getElementById(render.id)
      myChart = echarts.init(chartDom)
      loadData()
    }
    const loadData = async () => {
      if(!props.startAt)return
      const params = {
        startAt:props.startAt.getTime() / 1000,
        cycle:props.cycle,
        agentCode:props.agentCode || "",
      }
      let res = await axios.get("/admin/analyze/order/rate",{
        params
      })
      const legend = {
        bottom: '0%',
        left: 'center',
        textStyle:{
          fontSize:16,
        }
      }
      const data = [
        {
          name: t('chart.order_types.exchange'),
          value: res.ebox,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }, {
          name: t('chart.order_types.return'),
          value: res.return,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }, {
          name: t('chart.order_types.rent'),
          value: res.rent,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }, {
          name: t('chart.order_types.cbox'),
          value: res.cbox,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }, {
          name: t('chart.order_types.pile'),
          value: res.pile,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }, {
          name: t('chart.order_types.charge'),
          value: res.charge,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }, {
          name: t('chart.order_types.setmenu'),
          value: res.combo,
          tooltip:{
            textStyle:{
              fontSize:20,
            }
          }
        }
      ]
      const option = {
        title: {
          text: t('chart.order_ratio')
        },
        tooltip: {
          trigger: 'item'
        },
        legend,
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        series: [
          {
            name: t('chart.num'),
            type: 'pie',
            label: {
              formatter: '{b}:{c}({d})%',
              fontSize:16,
            },
            data
          }
        ]
      }

      option && myChart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })
    return {
      formData,
      render
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    >.chart-box{
      position: relative;
      >.header{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1000;
      }
      .chart{
        width: 100%;
        height: 22rem;
      }
    }
  }
</style>
