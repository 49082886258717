<template>
    <div class="content">
      <div class="card-box" v-if="isAdmin()">
        <div class="header">
          <span class="title">{{$t("g.condition")}}</span>
        </div>
        <div class="inp-list" >
            <div class="item">
                <span class="label">{{ $t('g.operator') }}</span>
                <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')" @change="func.OperatorChange" >
                    <el-option v-for="item in render.operatorList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
      </div>
      <!-- active-value=1 inactive-value=0 -->
      <div class="card-box">
        <div class="switch_title">小程序功能控制</div>
        <div class="switch_box_left">
            <div class="switch_box_switch"><span>短信验证：</span><el-switch v-model="formData.switchData.IsSms" active-text="启用"  inactive-text="禁用" inline-prompt  /></div> 
            <div class="switch_box_switch"><span>身份验证：</span><el-switch v-model="formData.switchData.IsIdVerify" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <div class="switch_box_switch"><span>换电清除套餐：</span><el-switch v-model="formData.switchData.IsReturnClear" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <!-- <div class="switch_box_switch"><span>保存电池信息：</span><el-switch v-model="formData.switchData.IsBatInfoSave" active-text="启用"  inactive-text="禁用" inline-prompt /></div> -->
            <div class="switch_box_switch"><span>开启优惠券：</span><el-switch v-model="formData.switchData.IsCoupon" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <div class="switch_box_switch"><span>开启暂存：</span><el-switch v-model="formData.switchData.IsStage" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <div class="switch_box_switch"><span>开启拉新：</span><el-switch v-model="formData.switchData.IsPull" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <!-- <div class="switch_box_switch"><span>独立商户：</span><el-switch v-model="formData.switchData.IsMch" active-text="启用"  inactive-text="禁用" inline-prompt /></div> -->
            <div class="switch_box_switch"><span>开启租套捆绑：</span><el-switch v-model="formData.switchData.IsRentAndCommbo" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <div class="switch_box_switch"><span>开启语音播报：</span><el-switch v-model="formData.switchData.IsTTs" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
            <div class="switch_box_switch"><span>开启逾期扣费：</span><el-switch v-model="formData.switchData.IsLateFee" active-text="启用"  inactive-text="禁用" inline-prompt /></div>
        </div>
        <el-button class="switch_box_btn" type="primary" round @click="func.save">保存设置</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import { reactive,onMounted,computed } from 'vue'
  import { updateOperators,operatorList} from '@/data/default'
  import { toDay } from '@/common/func'
  import axios from "axios"
  import { ElMessage } from 'element-plus'
  import i18n from '@/data/i18n/index'
  import { isOperator,isAdmin} from '@/data/token'
  
  export default {
    name: 'ExpensesAgents',
    setup () {
      const t  = i18n.global.t
      const render = {
        operatorList,
      }
      const formData = reactive({
        valOperator: '',
        switchData:{
            IsSms:false,
            IsIdVerify:false,
            IsReturnClear:false,
            // IsBatInfoSave:0,
            IsCoupon:false,
            IsStage:false,
            IsPull:false,
            // IsMch:0,
            IsRentAndCommbo:false,
            IsTTs:false,
            IsLateFee:false
        }
      })
      const func = {
        async save(){
          let primary
          if(!formData.valOperator&&isAdmin()){
              ElMessage({
                  type:'error',
                  message:'请选择运营商'
              })
              return
          }
          if(isAdmin()){
            primary = {AgentCode:formData.valOperator,...formData.switchData}
          }else{
            primary = {...formData.switchData}
          }
          
          console.log(primary,'提交的参数')
          let res = await axios.post(`/admin/agentfuncset/edit`,primary)
          console.log(res)
          if (res == '操作成功') {
            ElMessage({
              type: 'success',
              message: t('g.success')
            })
          } else {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }
        },
        async OperatorChange(){
            const params = {}
            if(isAdmin()){
              params.AgentCode = formData.valOperator
            }
            try{
                let res = await axios.get("/admin/agentfuncset/get",{params})
                formData.switchData = res.List[0]
                console.log(res,formData.valOperator)
            }catch(e){
                console.log("loadData",e)
            }
        },
      }
      onMounted(()=>{
        if(!isAdmin()){
          func.OperatorChange()
        }
      })
      return {
        func,
        render,
        formData,
        isOperator,
        isAdmin,
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
    >.card-box{
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
      .header{
        text-align:left;
        margin-bottom: 1.25rem;
        position: relative;
        >.title{
          font-size:1rem;
          font-weight: 500;
        }
        >.header-icons{
          position: absolute;
          right: 10px;
          top: 0px;
          z-index: 100;
          .header-icon{
            font-size: 0.875rem;
            font-weight: 500;
            border: 1px solid #ddd;
            color:#969696;
            padding: 0.25rem;
            border-radius: 50%;
            color: pointer;
            margin-left: 0.625rem;
            cursor: pointer;
          }
        }
      }
      >.btn-ct{
        text-align: right;
        :deep(.el-button--small){
          padding: 0.625rem 2rem;
        }
      }
      .inp-list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        >.item{
          width: 49%;
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
          .label{
            margin-right: 0.625rem;
            width: 4.375rem;
          }
          :deep(.el-range-editor--small.el-input__inner){
            flex: 1;
          }
          .inp{
            flex: 1;
          }
        }
      }
    }
    >.el-alert-my{
      margin-bottom: 1.25rem;
      box-shadow: $box-shadow;
      padding: 1.25rem;
      .el-alert-my-txt{
        font-size: 0.875rem;
        >.tips{
          font-weight: bold;
        }
      }
      :deep(.el-alert__closebtn){
        top: 1.25rem;
        font-size: 1rem;
      }
    }
    >.data-table{
      :deep(.el-table){
        .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .el-table__row{
          cursor: pointer;
        }
      }
    }
    >.pagination{
      text-align: right;
      margin-top: 1.25rem;
    }
    .el-form-row{
      display:flex;
      justify-content: space-between;
      :deep(.el-form-item){
        width: 49%;
      }
      .inp{
        width: 100%;
      }
    }
    .el-select-my{
      text-align: left;
      display: block;
    }
    :deep(.m-dialog){
      max-width: 50rem;
    }
  }
  @media screen and (max-width:800px) {
      .content{
        >.card-box{
          .inp-list{
            >.item{
              width: 100%;
            }
          }
        }
      }
  }

  .switch_box_switch{
    width: 25%;
    margin-bottom: 30px;
  }
  .switch_title{
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
  }
  .switch_box_left{
    display: flex;
    flex-wrap: wrap;
  }
  .switch_box_switch span{
    display: inline-block;
    width: 100px;
    text-align: left;
  }
  .switch_box_btn{
    margin:0 auto;
    margin-top: 50px;
  }
  </style>
  