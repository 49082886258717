<template>
  <div class="component">
    <div class="title">{{$t('member.month_card_expire')}}</div>
    <el-table
          :data="data.list"
          class="table"
          style="width: 100%">
          <el-table-column
            prop="Nickname"
            :label="$t('bill.nickname')"
            width="180">
          </el-table-column>
          <el-table-column
            prop="expired_in"
            :label="$t('member.expired_at')">
          </el-table-column>
          <el-table-column
            prop="AgentCode"
            :label="$t('g.agent_code')">
          </el-table-column>
          <el-table-column
            prop="reg_in"
            :label="$t('member.reg_at')">
          </el-table-column>
        </el-table>
  </div>
</template>

<script>
import axios from 'axios'
import { reactive,onMounted,watch } from 'vue'
import { toDay } from "@/common/func"
export default {
  name: 'MonthCardWillExpire',
  props:{
    agentCode:[String,Number],
  },
  setup (props) {
    const tableData = [{
    }]
    const data = reactive({
      list:[]
    })
    watch(()=>props,()=>{
      loadData()
    },{deep:true})
    const loadData = async () => {
      const params = {
        agentCode:props.agentCode || "",
      }
      let res = await axios.get("/admin/analyze/user/card",{
        params
      })
      res.map((item)=>{
        item.reg_in = toDay(item.CreatedAt)
        item.expired_in = toDay(item.ExpiredIn)
      })
      data.list = res
    }
    onMounted(()=>{
      loadData()
    })
    return {
      data
    }
  }
}
</script>

<style lang="scss" scoped>
  .component{
    .title{
      text-align: left;
      font-weight: 700;
      font-size: 1rem;
    }
  }
</style>
