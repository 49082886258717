<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{$t('decision.stat_condition')}}</span>
      </div>
      <div class="inp-list">
        <div class="item" v-if="!isOperator()">
          <span class="label">{{$t('g.operator')}}</span>
          <el-select size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.operators"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('decision.type')}}</span>
          <el-select size="small" v-model="formData.valType" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.types"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('decision.cycle')}}</span>
          <el-select size="small" v-model="formData.valCircle" :placeholder="$t('g.choose')">
              <el-option
                v-for="item in render.circles"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <div class="item">
          <span class="label">{{$t('decision.time')}}</span>
          <el-date-picker
            size="small"
            v-model="formData.valTime"
            :type="formData.valCircle==1?'year':'month'"
            :placeholder="$t('g.choose')">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div class="box-2" v-if="formData.valType == 0 || formData.valType == 4">
      <PropertiesStatusRadio :agentCode="formData.valOperator" id="cpbox" class="card-box"/>
      <PropertiesChargeStatusRadio :agentCode="formData.valOperator" id="cpbox" class="card-box"/>
    </div>
    <div class="box-2" v-if="formData.valType == 0 || formData.valType == 1">
      <PropertiesStatusRadio :agentCode="formData.valOperator" id="ebox" class="card-box"/>
      <PropertiesChargeStatusRadio :agentCode="formData.valOperator" id="ebox" class="card-box"/>
    </div>
    <div class="box-2" v-if="formData.valType == 0 || formData.valType == 2">
      <PropertiesStatusRadio :agentCode="formData.valOperator" id="cbox" class="card-box"/>
      <PropertiesChargeStatusRadio :agentCode="formData.valOperator" id="cbox" class="card-box"/>
    </div>
    <div class="box-2" v-if="formData.valType == 0 || formData.valType == 3">
      <PropertiesStatusRadio :agentCode="formData.valOperator" id="pile" class="card-box"/>
      <PropertiesChargeStatusRadio :agentCode="formData.valOperator" id="pile" class="card-box"/>
    </div>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="frequency-high" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="frequency-low" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="err" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="offline" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="lock" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="open" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="charge" class="card-box"/>
    <PropertiesRank :devType="formData.valType" :agentCode="formData.valOperator" :cycle="formData.valCircle" :startAt="formData.valTime" type="lack" class="card-box"/>
  </div>
</template>

<script>
import { reactive,onMounted } from 'vue'
import PropertiesRank from '@/components/charts/PropertiesRank.vue'
import PropertiesStatusRadio from '@/components/charts/PropertiesStatusRadio.vue'
import PropertiesChargeStatusRadio from '@/components/charts/PropertiesChargeStatusRadio.vue'
import { operators } from '@/data/default'
import axios from 'axios'
import i18n from '@/data/i18n/index'
import { isOperator } from '@/data/token'

export default {
  name: 'OperationProperties',
  components: {
    PropertiesRank,
    PropertiesChargeStatusRadio,
    PropertiesStatusRadio,
  },
  setup () {
    const t  = i18n.global.t
    const valOperator = 0
    const valType = 0
    const valCircle = 1
    //今年1月1号
    const now = new Date()
    const valTime = new Date(`${now.getFullYear()}/01/01 00:00:00`)
    const types = [
      {
        label: t('device.types.all'),
        value: 0
      },
      {
        label: t('device.types.ebox'),
        value: 1
      }, {
        label: t('device.types.cbox'),
        value: 2
      }, {
        label: t('device.types.pile'),
        value: 3
      }, {
        label: t('device.types.cpbox'),
        value: 4
      }
    ]
    const circles = [
      {
        label: t('decision.cycles.by_year'),
        value: 1
      }, {
        label: t('decision.cycles.by_month'),
        value: 2
      }
    ]
    const render = {
      operators,
      types,
      circles
    }
    const formData = reactive({
      valOperator,
      valType,
      valCircle,
      valTime
    })
    return {
      formData,
      render,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
    .card-box{
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
      .header{
        text-align:left;
        margin-bottom: 1.25rem;
        >.title{
          font-size:1rem;
          font-weight: 500;
        }
      }
      .inp-list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        >.item{
          margin-right: 3rem;
          display: flex;
          .label{
            width: 3rem;
            margin-right: 0.625rem;
          }
        }
      }
    }
    >.el-alert-my{
      margin-top: 1.25rem;
      box-shadow: $box-shadow;
      padding: 1.25rem;
      .el-alert-my-txt{
        font-size: 0.875rem;
        >.tips{
          font-weight: bold;
        }
      }
      :deep(.el-alert__closebtn){
        top: 1.25rem;
        font-size: 1rem;
      }
    }
    .statistic-box{
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.25rem;
      >.item{
        width: 24%;
        height: 11rem;
        background-color: #fff;
        box-shadow: $box-shadow;
        border-radius: 0.3rem;
        margin-bottom: 1.33%;
        margin-right: 1.33%;
        color: #fff;
        display: flex;
        flex-direction: column;
        &:nth-child(4){
          margin-right: 0;
        }
        >.key{
          width: 100%;
          padding: 1rem 0;
          color: $color-txt;
          background-color: #fff;
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
          font-size: 0.875rem;
        }
        >.val{
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: 3rem;
          >.sup{
            font-size: 1rem;
            margin-left: 0.625rem;
            padding-top: 1.25rem;
          }
        }
        &.info{
          background-color: $color-info;
        }
        &.success{
          background-color: $color-success;
        }
        &.danger{
          background-color: $color-danger;
        }
        &.warning{
          background-color: $color-warning;
        }
      }
    }
    >.box-2{
      display: flex;
      justify-content: space-between;
      >.card-box{
        width: 49%;
      }
    }
    .avg-lasted{
      background-color: #fff;
      box-shadow: $box-shadow;
      border-radius: 0.25rem;
      margin: 1.25rem 0;
      padding: 1.25rem 0;
      background-color: $color-warning;
      color: #fff;
      >.val{
        font-weight: 500;
        margin-left: 1.25rem;
      }
    }
  }
  @media screen and (max-width:900px) {
      .content{
        >.box-2{
          flex-wrap: wrap;
          >.card-box{
            width: 100%;
            .inp-list{
              flex-wrap: wrap;
              >.item{
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
                display: flex;
                align-items: center;
                .label{
                  width: 5rem;
                  text-align: right;
                }
              }
            }
          }
        }
      }
  }
</style>
