<template>
  <div class="content">
    <div class="card-box">
      <!-- <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
      </div> -->
      <div class="btn-ct">

        <el-button type="primary" @click="func.newActivity" class="new-btn" size="small">新建活动</el-button>
        <!-- <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button> -->
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%">
        <el-table-column prop="Id" label="#">
        </el-table-column>
        <el-table-column prop="Name" label="名称" :width="100"></el-table-column>
        <el-table-column prop="AgentCode" label="运营商" :width="100" v-if="isAdmin()"></el-table-column>
        <el-table-column prop="Province" label="省" :width="80"></el-table-column>
        <el-table-column prop="City" label="市" :width="80"></el-table-column>
        <el-table-column prop="EnableMode" label="状态" :width="80">
          <template #default="scope">
            <el-tag :type="scope.row.EnableMode ? 'success' : 'warning'">{{
              render.statuses[scope.row.EnableMode].label }}</el-tag></template>
        </el-table-column>
        <el-table-column prop="UserPulledCouponId" label="被邀请人赠送优惠券ID" :width="200"></el-table-column>
        <el-table-column prop="VipIncomeMode" label="VIP收入模式" :width="130">
          <template #default="scope">
            {{ render.VIPawardType[scope.row.VipIncomeMode].label }}
          </template>
        </el-table-column>
        <el-table-column prop="VipFirstMonIncome" label="VIP首次收入" :width="130"></el-table-column>
        <el-table-column prop="VipLaterMonIncome" label="VIP后续收入" :width="140"></el-table-column>
        <el-table-column prop="VipLaterMonIncomeNum" label="VIP次数" :width="100"></el-table-column>
        <el-table-column prop="IncomeMode" label="收入模式" :width="100">
          <template #default="scope">
            {{ render.awardType[scope.row.IncomeMode].label }}
          </template>
        </el-table-column>
        <el-table-column prop="FirstMonIncome" label="首次收入" :width="100">
          <template #default="scope">
            {{ scope.row.IncomeMode != 2 ? scope.row.FirstMonIncome : scope.row.UserPullCoupon?.Value }}
          </template>
        </el-table-column>
        <el-table-column prop="LaterMonIncome" label="后续收入" :width="120">
          <template #default="scope">
            {{ scope.row.IncomeMode != 2 ? scope.row.LaterMonIncome : scope.row.UserPullLateCoupon?.Value }}
          </template>
        </el-table-column>
        <el-table-column prop="LaterMonIncomeNum" label="次数" :width="100"></el-table-column>
        <el-table-column prop="CreatedAtStr" label="创建时间" :width="200"></el-table-column>
        <el-table-column prop="UpdatedAtStr" label="更新时间" :width="200"></el-table-column>
        <el-table-column :width="150" :label="$t('g.operation')" fixed="right">
          <template #default="scope">
            <el-tag size="large" type="danger" @click="func.del(scope.row.Id)">删除</el-tag>
            <el-tag size="large" type="success" style="margin-left: 1rem;"
              @click="func.editDialog(scope.row)">修改</el-tag>
          </template>
        </el-table-column>
      </el-table>

    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="formData.operateType == 1 ? '新建' : '编辑'" v-model="formData.addElDailog" width="80%" center>
      <div class="dailog-content">
        <div class="activity-content">

          <el-form label-width="180px" :rules="rules" :model="formData">
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('activityManage.title')" prop="name">
                  <el-input v-model="formData.Name" />
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="isAdmin()">
                <el-form-item :label="$t('g.operator')" prop="name">
                  <el-select class="inp" size="small" v-model="formData.AgentCode" :placeholder="$t('g.choose')">
                    <el-option v-for="item in render.operators" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('activityManage.time')" prop="startTime">
                  <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small"
                    type="daterange" unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
                    :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('member.status')">
                  <el-select class="inp" v-model="formData.EnableMode" :placeholder="$t('g.choose')">
                    <el-option v-for="item in render.statuses" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="省市">
                  <el-cascader style="width:100%" :options="provinceAndCityData"
                    :props="{ checkStrictly: true, value: 'label', label: 'label' }" v-model="formData.Province"
                    @change="func.handleChange">
                  </el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-form-item :label="$t('activityManage.type')" width="350px">
              <el-select class="inp" size="small" v-model="formData.valCode1" :placeholder="$t('g.choose')">
                <el-option v-for="item in render.activityType" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-row>
              <el-col :span="24">
                <el-form-item label="VIP邀请人收益模式">
                  <!-- <el-select class="inp" v-model="formData.VIPawardType" :placeholder="$t('g.choose')"
                  style="width:100%">
                  <el-option v-for="item in render.VIPawardType" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select> -->
                  <el-radio-group v-model="formData.VipIncomeMode">
                    <el-radio v-for="item in render.VIPawardType" :key="item.value" :label="item.value"
                      :value="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="VIP邀请人首次奖励">
                  <el-input v-model.number="formData.VipFirstMonIncome">
                    <template #append>{{ formData.VipIncomeMode == 0 ? '元' : '%' }}</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="VIP邀请人后续次数">
                  <el-input v-model.number="formData.VipLaterMonIncomeNum">
                    <template #append>次</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="formData.VipLaterMonIncomeNum == '' || formData.VipLaterMonIncomeNum > 0">
                <el-form-item label="VIP邀请人后续奖励">
                  <el-input v-model.number="formData.VipLaterMonIncome">
                    <template #append>{{ formData.VipIncomeMode == 0 ? '元' : '%' }}</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="普通邀请人收益模式">
                  <el-radio-group v-model="formData.IncomeMode">
                    <el-radio v-for="item in render.awardType" :key="item.value" :label="item.value"
                      :value="item.value">{{
                        item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="普通邀请人首次奖励">
                  <el-input v-model.number="formData.FirstMonIncome" v-if="formData.IncomeMode != 2">
                    <template #append>{{ ['元', '%', '天'][formData.IncomeMode] }}</template>
                  </el-input>
                  <el-select class="inp" v-model="formData.FirstMonIncome" :placeholder="$t('g.choose')"
                    style="width:100%" v-else>
                    <el-option v-for="item in render.couponList" :key="item.value" :label="item.label"
                      :value="item.value" :disabled="item.expired || !Boolean(item.remainNum)">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="
                          float: right;
                          color: var(--el-text-color-secondary);
                          font-size: 13px;
                        ">{{ item.expired ? "过期" : item.remainNum }} </span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="普通邀请人后续次数">
                  <el-input v-model.number="formData.LaterMonIncomeNum">
                    <template #append>次</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="formData.LaterMonIncomeNum == '' || formData.LaterMonIncomeNum > 0">
                <el-form-item label="普通邀请人后续奖励">
                  <el-input v-model.number="formData.LaterMonIncome" v-if="formData.IncomeMode != 2"><template
                      #append>{{ ['元', '%',
                        '天'][formData.IncomeMode] }}</template></el-input>
                  <el-select class="inp" v-model="formData.LaterMonIncome" :placeholder="$t('g.choose')"
                    style="width:100%" v-else>
                    <el-option v-for="item in render.couponList" :key="item.value" :label="item.label"
                      :value="item.value" :disabled="item.expired || !Boolean(item.remainNum)">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="
                          float: right;
                          color: var(--el-text-color-secondary);
                          font-size: 13px;
                        ">{{ item.expired ? "过期" : item.remainNum }} </span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item label="被邀请人赠送优惠券">
                  <el-select class="inp" v-model="formData.UserPulledCouponId" :placeholder="$t('g.choose')"
                    style="width:100%">
                    <el-option v-for="item in render.couponList" :key="item.value" :label="item.label"
                      :value="item.value" :disabled="item.expired || !Boolean(item.remainNum)">
                      <span style="float: left">{{ item.label }}</span>
                      <span style="
                          float: right;
                          color: var(--el-text-color-secondary);
                          font-size: 13px;
                        ">{{ item.expired ? "过期" : item.remainNum }} </span>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col></el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="$t('activityManage.award_rule')" width="350px">
                  <el-input v-model="formData.ReMark" :rows="7" type="textarea" placeholder="请输入活动规则" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="formData.addElDailog = false">取消</el-button>
          <el-button type="primary" @click="func.add">
            确认
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, onMounted, computed } from 'vue'
import activityStyle from '@/components/activityMode/activityStyle.vue'
import { toDay } from '@/common/func'
import i18n from '@/data/i18n/index'
import { user, isOperator, isAdmin } from '@/data/token'
import { shortcuts, operators } from '@/data/default'
import Recharge from '../order/recharge.vue'
import axios from "axios"
import { ElMessage, ElMessageBox, ElLoading, dayjs } from 'element-plus'
import { provinceAndCityData } from 'element-china-area-data'
export default {
  name: 'activityManage',
  components: {
    activityStyle
  },
  setup() {
    const t = i18n.global.t
    const couponList = []
    const render = {
      shortcuts,
      couponList,
      statuses: [
        {
          label: t('device.disable'),
          value: 0
        },
        {
          label: t('device.enable'),
          value: 1
        }
      ],
      activityType: [
        {
          label: '分享注册加换电时间',
          value: 0
        }
      ],
      awardType: [
        {
          label: '现金固定',
          value: 0
        },
        {
          label: '现金百分比',
          value: 1
        },
        {
          label: '优惠券',
          value: 2
        },
      ],
      VIPawardType: [
        {
          label: '现金固定',
          value: 0
        },
        {
          label: '现金百分比',
          value: 1
        }
      ],
      awardUnit: [
        {
          label: '分钟',
          value: 0
        },
        {
          label: '天',
          value: 0
        },
        {
          label: '元',
          value: 0
        }
      ],
      operators,
    }
    const rules = reactive({
    })
    const formData = reactive({
      tableData: [],
      curPage: 1,
      pageSize: 10,
      total: 0,
    })
    const func = {
      newActivity() {
        formData.operateType = 1
        formData.EnableMode = 1
        formData.addElDailog = true;
      },
      async search() {
        func.loadData()
      },
      editDialog(row) {
        formData.operateType = 2
        if (row) {
          formData.Id = row.Id
          formData.Name = row.Name
          formData.EnableMode = row.EnableMode
          formData.AgentCode = row.AgentCode
          formData.Province = []
          if (row.Province) {
            formData.Province.push(row.Province)
            if (row.City) {
              formData.Province.push(row.City)
            }
          }

          formData.VipIncomeMode = row.VipIncomeMode
          formData.ReMark = row.ReMark
          formData.valTime = []
          if (row.StartTime) {
            formData.valTime.push(dayjs.unix(row.StartTime).format('YYYY-MM-DD'))
          }
          if (row.EndTime) {
            formData.valTime.push(dayjs.unix(row.EndTime).format('YYYY-MM-DD'))
          }
          formData.VipFirstMonIncome = row.VipFirstMonIncome
          formData.VipLaterMonIncomeNum = row.VipLaterMonIncomeNum
          formData.VipLaterMonIncome = row.VipLaterMonIncome
          formData.IncomeMode = row.IncomeMode
          formData.FirstMonIncome = row.FirstMonIncome
          formData.FirstMonth = row.FirstMonth
          formData.LaterMonIncomeNum = row.LaterMonIncomeNum
          formData.LaterMonIncome = row.LaterMonIncome
          formData.UserPulledCouponId = row.UserPulledCouponId


          // formData.City = row.City
        }
        formData.addElDailog = true;
      },
      async del(Id) {
        ElMessageBox.confirm(t('g.sure_delete'), {
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
        }).then(() => {
          try {
            axios.post('/admin/pullset/delete', {
              Id
            }).then((res) => {
              if (res == '操作成功') {
                ElMessage({
                  type: 'success',
                  message: t('g.success')
                })
              } else {
                ElMessage({
                  type: 'error',
                  message: t("err_code.20005")
                })
              }
              func.loadData()
            })

          } catch (error) {
            ElMessage({
              type: 'error',
              message: t("err_code.20005")
            })
          }

        })


      },
      async add(id) {
        let params = {
          vipIncomeMode: formData.VipIncomeMode,
          incomeMode: formData.IncomeMode,
        }
        if (formData.Name) {
          params.name = formData.Name
        }
        if (formData.AgentCode) {
          params.agentCode = formData.AgentCode
        }

        if (formData.Province) {
          params.province = formData.Province[0]
        }
        if (formData.Province && formData.Province.length > 1) {
          params.city = formData.Province[1]
        }

        params.enableMode = formData.EnableMode

        if (formData.ReMark) {
          params.Remark = formData.ReMark
        }
        if (formData.valTime) {
          params.StartTime = Math.floor(new Date(formData.valTime[0]).getTime() / 1000)
          params.EndTime = Math.floor(new Date(formData.valTime[1] + " 23:59:59").getTime() / 1000)
        }

        if (formData.UserPulledCouponId) {
          params.userPulledCouponId = formData.UserPulledCouponId
        }
        if (formData.VipIncomeMode) {
          params.vipIncomeMode = formData.VipIncomeMode
        }
        if (formData.VipFirstMonIncome) {
          params.vipFirstMonIncome = formData.VipFirstMonIncome
        }
        if (formData.VipLaterMonIncome) {
          params.vipLaterMonIncome = formData.VipLaterMonIncome
        }
        if (formData.VipLaterMonIncomeNum) {
          params.vipLaterMonIncomeNum = formData.VipLaterMonIncomeNum
        }
        if (formData.IncomeMode) {
          params.incomeMode = formData.IncomeMode
        }
        if (formData.FirstMonIncome) {
          params.firstMonIncome = formData.FirstMonIncome
        }
        if (formData.LaterMonIncome) {
          params.laterMonIncome = formData.LaterMonIncome
        }
        if (formData.LaterMonIncomeNum) {
          params.laterMonIncomeNum = formData.LaterMonIncomeNum
        }
        let res
        if (formData.operateType == 2) {
          params.id = formData.Id
          res = await axios.post('/admin/pullset/edit', params);
        } else {
          res = await axios.post('/admin/pullset/add', params);
        }
        console.log(JSON.stringify(res));

        if (res) {
          formData.addElDailog = false;
          func.loadData()
          ElMessage({
            type: 'success',
            message: t('g.success')
          })
        } else {
          ElMessage({
            type: 'error',
            message: t('err_code.20017')
          })
        }

      },
      async loadData(page = 1) {
        let params = {
          page,
        }
        if (formData.id) {
          params.id = formData.id
        }
        if (formData.name) {
          params.name = formData.name
        }
        if (formData.agentCode) {
          params.agentCode = formData.agentCode
        }
        let res = await axios.get('/admin/pullset/get', {
          params,
        })
        if (res) {
          formData.total = res.Total
          res.List.map((item) => {
            item.CreatedAtStr = toDay(item.CreatedAt)
            item.UpdatedAtStr = toDay(item.UpdatedAt)
          })
          formData.pageSize = res.PageSize
          formData.tableData = res.List
        }
      },
      handleChange() {

      },
      async getCoupon() {
        try {
          let page = 1
          let params = {
            page,
            enable: 1,
            type: 1,
            distributeMethod: 1
          }
          let res = await axios.get('/admin/coupon/get', {
            params,
          })
          res.List.map((item) => {

            couponList.push(
              {
                label: item.Name,
                value: item.Id,
                num: item.Value,
                type: item.Type,
                expired: dayjs().add(-1, 'day').isAfter(dayjs.unix(item.EndTime)),
                // item.EndTime<Math.floor((new Date()).valueOf()/1000),//,
                DistributeNum: item.DistributeNum,
                DistributedNum: item.DistributedNum,
                remainNum: item.DistributeNum - item.DistributedNum,
              }
            )


          })
          console.log(JSON.stringify(couponList), 238032);


        } catch (e) {
          console.log("loadData", e)
        }
      },

    }
    onMounted(() => {
      if (provinceAndCityData[0].value != 0) {
        provinceAndCityData.unshift({
          value: "0",
          label: "全部"
        })
      }
      func.getCoupon()
      func.loadData()
      // func.loadData()
    })
    return {
      formData,
      func,
      formData,
      render,
      token: user.token,
      isOperator,
      isAdmin,
      rules,
      provinceAndCityData,
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    >.pagination {
      text-align: right;
      margin-top: 1.25rem;
    }

  }

  .new-el-dialog {
    .dailog-content {
      display: flex;
    }

    .activity-content {
      flex: 2
    }

    // .activity-style {
    //   flex: 1;
    // }


  }

  :deep(.m-dialog) {
    // max-width: 80rem;
    margin-top: 0;
  }
}
</style>