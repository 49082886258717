<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.order_no') }}</span>
          <el-input class="inp" size="small" v-model="formData.valOrderNo"
            :placeholder="$t('openSlotsLit.order_no')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.open_slots_result') }}</span>
          <el-select class="inp" size="small" v-model="formData.valResult" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.results" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.open_slots_type') }}</span>
          <el-select class="inp" size="small" v-model="formData.valTypes" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.types" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item" v-if="!isOperator()">
          <span class="label">{{ $t('g.operator') }}</span>
          <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.dev_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName"
            :placeholder="$t('openSlotsLit.dev_id')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.site_name') }}</span>
          <el-input class="inp" size="small" v-model="formData.valSiteName"
            :placeholder="$t('openSlotsLit.site_name')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('openSlotsLit.user_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valuserId"
            :placeholder="$t('openSlotsLit.user_id')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.exportAllExcel(1)" class="search" type="info" size="small">{{ $t('openSlotsLit.export_details') }}</el-button>
        <el-button @click="func.exportAllExcel(0)" class="search" type="info" size="small">{{ $t('openSlotsLit.order_export') }}</el-button>
        <!-- <el-button @click="func.exportExcel" class="search" type="info" size="small">导出</el-button> -->
        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
        }}</span>
        <div class="header-icons">
          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" @expand-change="func.expand"
      @row-click="func.showDetails"
      style="width: 100%" id="exportTab">
        <el-table-column type="expand">
          <template #default="scope">
            <div class="table-detail">
              <div class="item" v-if="!formData.slots[scope.row.OrderSn]">{{ $t('device.slot_loading') }}</div>
              <div class="slots" v-else>
                <div class="slot" v-for="(item, idx) in formData.slots[scope.row.OrderSn]" :key="idx">
                  <div>{{ $t('openSlotsLit.slot_no') }} : {{ item.SlotId }}</div>
                  <div :style="{ color: item.Res == 'OK' ? '#67C23A' : '#E6A23C' }">{{ $t('openSlotsLit.open_slots_result') }} : {{
                    item.Res == 'OK' ? $t('openSlotsLit.result.success') : item.Res }}</div>
                  <div>{{ $t('openSlotsLit.ins_battery_soc') }}: {{ item.InSoc || $t('openSlotsLit.no_data') }}</div>
                  <div :style="{ width: item.InBatId ? '14rem' : '14rem' }">{{ $t('openSlotsLit.ins_battery_id') }} : {{
                    item.InBatId || $t('openSlotsLit.no_data') }}</div>
                  <div>{{ $t('openSlotsLit.pull_out_battery_soc') }}: {{ item.OutSoc || $t('openSlotsLit.no_data') }}</div>
                  <div :style="{ width: item.OutBatId ? '14rem' : '14rem' }">{{ $t('openSlotsLit.pull_out_battery_id') }} : {{
                    item.OutBatId || $t('openSlotsLit.no_data') }}</div>
                  <div style="width:15rem">{{ $t('openSlotsLit.create_time') }} : {{ item.createAtStr }}</div>

                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="AgentCode" v-if="!isOperator()" :label="$t('openSlotsLit.agent_code')" width="100">
        </el-table-column>
        <el-table-column prop="SiteName" width="120" :label="$t('openSlotsLit.site_name')">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('openSlotsLit.dev_id')" width="250">
        </el-table-column>
        <el-table-column prop="UserId" :label="$t('openSlotsLit.user_id')" width="80">
        </el-table-column>
        <el-table-column prop="OpenTypeStr" :label="$t('openSlotsLit.open_slots_type')" width="100">

        </el-table-column>

        <el-table-column prop="OrderSn" width="200" :label="$t('openSlotsLit.order_no')">
        </el-table-column>
        <el-table-column prop="Deposit" width="120" :label="$t('openSlotsLit.open_slots_info')">
        </el-table-column>
        <el-table-column prop="Res" width="120" :label="$t('openSlotsLit.open_slots_result')">
          <template #default="scope">
            <span :style="{ color: scope.row.Res == 'OK' ? 'green' : 'red' }">
              {{ scope.row.Res == 'OK' ? $t('openSlotsLit.result.success') : scope.row.Res }}
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="CreateAtStr" min-width="180" :label="$t('g.create_at')">
        </el-table-column>

      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog
    :title="$t('g.detail')" 
    v-model="formData.dialogDisplayVisible"
    width="80%"
    >
    <el-descriptions border column="4">
      <el-descriptions-item label="Id">{{ formData.curData.Id }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.site_name')">{{ formData.curData.SiteName }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.dev_id')">{{ formData.curData.DevId }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.user_id')">{{ formData.curData.UserId }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.open_slots_type')">{{ formData.curData.OpenTypeStr }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.order_no')">{{ formData.curData.OrderSn }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.open_slots_info')">{{ formData.curData.Deposit }}</el-descriptions-item>
      <el-descriptions-item :label="$t('openSlotsLit.open_slots_result')"><span :style="{ color: formData.curData.Res == 'OK' ? 'green' : 'red' }">
              {{ formData.curData.Res == 'OK' ? $t('openSlotsLit.result.success') : formData.curData.Res }}
            </span></el-descriptions-item>
      <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
    </el-descriptions>
      <el-table :data="formData.slots[formData.curData.OrderSn]" border style="margin-top:20px">
        <el-table-column prop="SlotId" :label="$t('openSlotsLit.slot_no')" width='60'></el-table-column>
        <el-table-column prop="SlotId" :label="$t('openSlotsLit.open_slots_result')" >
          <template #default="scope">
            <div :style="{ color: scope.row.Res == 'OK' ? '#67C23A' : '#E6A23C' }">{{
                    scope.row.Res == 'OK' ? $t('openSlotsLit.result.success') : scope.row.Res }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="InSoc" :label="$t('openSlotsLit.ins_battery_soc')" width='120'></el-table-column>
        <el-table-column prop="InBatId" :label="$t('openSlotsLit.ins_battery_id')"></el-table-column>
        <el-table-column prop="OutSoc" :label="$t('openSlotsLit.pull_out_battery_soc')" width='120'></el-table-column>
        <el-table-column prop="OutBatId" :label="$t('openSlotsLit.pull_out_battery_id')"></el-table-column>
        <el-table-column prop="createAtStr" :label="$t('openSlotsLit.create_time')" width="180"></el-table-column>
        
      </el-table>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
    </template>
    </el-dialog>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogVisible"  width="80%">
      <el-form  label-width="80px" :model="formData.curData">
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.status')">
            <el-select class="inp" size="small" v-model="formData.curData.Status" :placeholder="$t('g.choose')">
              <el-option v-for="item in render.updateStatuses" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('device.deposit2')">
            <el-input size="small" type="number" v-model="formData.curData.Deposit"></el-input>
          </el-form-item>
          <el-form-item :label="$t('bill.settle_rate')">
            <el-input size="small" type="number" v-model="formData.curData.CountRate"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.longitude')">
            <el-input size="small" type="number" v-model="formData.curData.Longitude"></el-input>
          </el-form-item>
          <el-form-item :label="$t('device.latitude')">
            <el-input size="small" type="number" v-model="formData.curData.Latitude"></el-input>
          </el-form-item>
        </div>
        <div class="el-form-row line-3">
          <el-form-item :label="$t('device.img_dev')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgBox' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgBox" :src="formData.curData.ImgBox" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_site')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStore' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgStore" :src="formData.curData.ImgStore" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item :label="$t('device.img_env')">
            <el-upload class="avatar-uploader" name="file" :action="uploadUrl" :multiple="false" :show-file-list="false"
              :data="{ 'id': 'ImgStreet' }" :headers="{ Authorization: token }" :on-error="func.onUploadError"
              :on-success="func.onUploadSuccess" :before-upload="func.onUploadBefore">
              <img v-if="formData.curData.ImgStreet" :src="formData.curData.ImgStreet" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </div>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="func.updateData()">{{ $t('g.btn_confirm') }}</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operators } from '@/data/default'
import { toDay, toCounterTime } from '@/common/func'
import axios from "axios"
import { ElMessage, ElMessageBox,ElLoading } from 'element-plus'
import cfg from '@/common/conf'
import { user, isOperator } from '@/data/token'
import i18n from '@/data/i18n/index'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  name: 'openSlotsList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const statuses = [
      {
        label: t('device.statuses.all'),
        value: -1
      },
      {
        label: t('device.statuses.enabled'),
        value: 1
      },
      {
        label: t('device.statuses.disabled'),
        value: 0
      }
    ]

    const render = {
      statuses,
      operators,
      types: [
        {
          label: t('openSlotsLit.types.all'),
          value: -1
        },
        {
          label: t('openSlotsLit.types.all_slot'),
          value: 0
        },
        {
          label: t('openSlotsLit.types.all_empty_slot'),
          value: 1
        },
        {
          label: t('openSlotsLit.types.all_battery_slot'),
          value: 2
        },
        {
          label: t('openSlotsLit.types.Specify_slot'),
          value: 3
        },
        {
          label: t('openSlotsLit.types.full_charge_slot'),
          value: 4
        },
      ],
      results: [
        {
          label: t('openSlotsLit.result.all'),
          value: -1
        },
        {
          label: t('openSlotsLit.result.success'),
          value: 'OK'
        },
        {
          label: t('openSlotsLit.result.fail'),
          value: 'FAIL'
        },
      ],
      updateStatuses: [
        {
          label: t('device.enable'),
          value: 1
        },
        {
          label: t('device.disable'),
          value: 0
        }
      ],
      alerts: [
        {
          label: t('device.statuses.all'),
          value: -1
        },
        {
          label: t('device.statuses.online'),
          value: 0
        },
        {
          label: t('device.statuses.offline'),
          value: 1
        }
      ],
      shortcuts
    }
    const formData = reactive({
      tableData,
      slots: {},
      curData: {},
      valTime: '',
      valTypes: -1,
      valResult: -1,
      dialogVisible: false,
      dialogDisplayVisible:false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      UpdateAtLast: {},//电池更新最后时间
    })
    const func = {
      showDetails(row){
        func.expand(row,[1])
        formData.dialogDisplayVisible = true;
        formData.curData = row;
      },
      async exportAllExcel(type=1) {


        let params = {

        }
        if (formData.valTypes != -1) {
          params.openType = formData.valTypes
        }
        if (formData.valResult != -1) {
          params.Res = formData.valResult
        }
        if (formData.valOrderNo) {
          params.orderNum = formData.valOrderNo
        }
        if (formData.valCode) {
          params.agentCode = formData.valCode
        }
        if (formData.valDevName) {
          params.devId = formData.valDevName
        }
        if (formData.valSiteName) {
          params.siteName = formData.valSiteName
        }
        if (formData.valuserId) {
          params.userid = formData.valuserId
        }
        if(type){
          params.exportType = type
        }
        if (formData.valTime) {
          params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
          params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
        }
        let loading = ''
        if(params.exportType==1){
          loading = ElLoading.service({
            lock: true,
            text: t('openSlotsLit.loading'),
            background: 'rgba(0, 0, 0, 0.7)',
          })
        }
        
        let res = await axios.get(`/admin/openslot/export`, {
          params
        });
        let excleData = [[]];
        let wscols = [];
        if(params.exportType==1){
            setTimeout(() => {
              loading.close()
            }, 200)
           excleData = [[
              'ID',
              t('openSlotsLit.agent_code'),
              t('openSlotsLit.site_name'),
              t('openSlotsLit.dev_id'),
              t('openSlotsLit.user_id'),
              t('openSlotsLit.open_slots_type'),
              t('openSlotsLit.order_no'),             
              t('openSlotsLit.slot_no'),
              t('openSlotsLit.open_slots_result'),
              t('openSlotsLit.ins_battery_soc'),
              t('openSlotsLit.ins_battery_id'),
              t('openSlotsLit.pull_out_battery_soc'),
              t('openSlotsLit.pull_out_battery_id'),
              t('openSlotsLit.create_time'),
          ]];
          wscols = [    // 每列不同宽度px
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 25 },
            { wch: 12 },
            { wch: 12 },
            { wch: 22 },           
            { wch: 4 },
            { wch: 10 },
            { wch: 10 },
            { wch: 18 },
            { wch: 10 },
            { wch: 18 },
            { wch: 22 },
          ];
        }else{
         excleData = [[
              'ID',
              t('openSlotsLit.agent_code'),
              t('openSlotsLit.site_name'),
              t('openSlotsLit.dev_id'),
              t('openSlotsLit.user_id'),
              t('openSlotsLit.open_slots_type'),
              t('openSlotsLit.order_no'),
              t('openSlotsLit.open_slots_result'),
              t('g.create_at'),
          ]];
          wscols = [    // 每列不同宽度px
            { wch: 10 },
            { wch: 10 },
            { wch: 10 },
            { wch: 25 },
            { wch: 12 },
            { wch: 12 },
            { wch: 22 },
            { wch: 15 },
            { wch: 22 },
          ];
        }
        const dictType = [
          t('openSlotsLit.types.all_slot'),
          t('openSlotsLit.types.all_empty_slot'),
          t('openSlotsLit.types.all_battery_slot'),
          t('openSlotsLit.types.Specify_slot'),
          t('openSlotsLit.types.full_charge_slot'),
        ]
        if (res && res.List) {
          for (let item of res.List) {
            let arr = [];
            // arr =[
            //       item.Id,
            //       item.OrderInfo.AgentCode,
            //       item.OrderInfo.SiteName,
            //       item.OrderInfo.DevId,
            //       item.OrderInfo.UserId,
            //       dictType[item.OrderInfo.OpenType],
            //       item.OrderInfo.OrderSn,
            //       item.OrderInfo.Res,
            //       toDay(item.OrderInfo.CreatedAt),
            // ]
            // excleData.push(arr)
            if(params.exportType==1){
              
                arr = [
                  item.OrderId,
                  item.AgentCode,
                  item.SiteName,
                  item.DevId,
                  item.UserId,
                  dictType[item.OpenType],
                  item.OrderSn,                  
                  item.SlotId,
                  item.info_res,
                  item.InSoc,
                  item.InBatId,
                  item.OutSoc,
                  item.OutBatId,
                  toDay(item.info_created_at&&item.order_created_at),
                ]
                excleData.push(arr);
              
            }else{
              arr =[
                  item.Id,
                  item.AgentCode,
                  item.SiteName,
                  item.DevId,
                  item.UserId,
                  dictType[item.OpenType],
                  item.OrderSn,
                  item.Res,
                  toDay(item.CreatedAt),
                ]
                excleData.push(arr)
            }
           
           
          }
        }
        
        const worksheet = XLSX.utils.aoa_to_sheet(excleData);
        worksheet["!cols"] = wscols;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, t('openSlotsLit.opening_information_table')+'.xlsx');
      },
      exportExcel() {
        const excleData = [['ID', t('openSlotsLit.agent_code'), t('openSlotsLit.site_name'), t('openSlotsLit.dev_id'), t('openSlotsLit.user_id'), t('openSlotsLit.open_slots_type'), t('openSlotsLit.order_no'), t('openSlotsLit.open_slots_result'), t('g.create_at')]];
        const dictType = [
          t('openSlotsLit.types.all_slot'),
          t('openSlotsLit.types.all_empty_slot'),
          t('openSlotsLit.types.all_battery_slot'),
          t('openSlotsLit.types.Specify_slot'),
          t('openSlotsLit.types.full_charge_slot')]
        if (formData.tableData) {
          for (let item of formData.tableData) {
            let arr = [item.Id, item.AgentCode, item.SiteName, item.DevId, item.UserId, dictType[item.OpenType], item.OrderSn, item.Res, toDay(item.CreatedAt)]
            excleData.push(arr)
          }
        }
        let wscols = [    // 每列不同宽度px
          { wch: 10 },
          { wch: 10 },
          { wch: 10 },
          { wch: 25 },
          { wch: 12 },
          { wch: 12 },
          { wch: 22 },
          { wch: 15 },
          { wch: 22 },
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(excleData);
        worksheet["!cols"] = wscols;
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, '开仓信息表.xlsx');
        return;
        /* generate workbook object from table */
        let xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
        let wb = XLSX.utils.table_to_book(document.querySelector('#exportTab'), xlsxParam)
        /* get binary string as output */
        let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })

        try {
          FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '开仓信息表.xlsx')
        } catch (e) {
          if (typeof console !== 'undefined') {
            console.log(e, wbout)
          }
        }
        return wbout;
      },
      async openSlot(devId, slotId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_open_slot'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          await axios.post('/admin/device/openSlot', {
            devId,
            slotId
          })
          ElMessage({
            type: 'success',
            message: t('device.cmd_sent'),
          })
        } catch (e) {

        }
      },
      async restartDev(devId) {
        try {
          await ElMessageBox.confirm(
            t('device.sure_restart_dev'),
            t('tips'),
            {
              confirmButtonText: t('confirm'),
              cancelButtonText: t('cancel'),
              type: 'warning',
            }
          )
          let res = await axios.post('/admin/device/restartDev', {
            devId
          })
          if (res.indexOf('成功')) {
            ElMessage({
              type: 'success',
              message: res,
            })
          } else {
            ElMessage({
              type: 'error',
              message: res,
            })
          }

        } catch (e) {

        }
      },
      toCounterTime,
      async toggleSlotStatus(item) {
        let res = await axios.post('/admin/device/disableSlot', {
          slotId: item.Id,
          disableInt: item.Status === 0 ? 0 : 1,
        })
        item.Status = 1 - item.Status
        ElMessage({
          type: 'success',
          message: t('g.success')
        })
      },
      async expand(row, state) {
        if (state.length) {
          const res = await axios.get("/admin/openslot/getslotinfo?orderNum=" + row.OrderSn)
          res.map(item => {
            item.createAtStr = toDay(item.CreatedAt)
          })
          formData.slots[row.OrderSn] = res
        }
      },
      onUploadBefore() {
        console.log("onUploadBefore")
      },
      onUploadError() {
        ElMessage({
          type: 'error',
          message: t('g.upload_failed')
        })
      },
      onUploadSuccess(res) {
        // console.log("上传结果", res)
        if (res.Code != 200) {
          return ElMessage({
            type: 'error',
            message: res.Msg
          })
        }
        const { id, url } = res.Data
        formData.curData[id] = url
      },
      search() {
        // console.log('formData', formData)
        func.loadData()
      },
      clear() {
        formData.valTypes = -1;
        formData.valResult = -1;
        formData.valOrderNo = '';
        formData.valCode = '';
        formData.valDevName = '';
        formData.valuserId = '';
        formData.valSiteName = '';
        formData.valTime = '';
        func.loadData()
      },
      rowDblClick(row, column, e) {
        // console.log('rowDblClick', row, column, e)
        formData.curData = row
        formData.dialogVisible = true
      },
      pageChange(val) {
        // console.log('page change', val)
        func.loadData(val)
      },
      showAddDataDialog() {
        formData.curData = {
        }
        formData.dialogVisible = true
      },
      async updateData() {
        // console.log("updateOrCreate Data", formData.curData)
        if (typeof (formData.curData.Status) == 'undefined') {
          ElMessage({
            type: 'error',
            message: t("device.please_choose_status")
          })
          return
        }
        try {
          const params = {
            id: formData.curData.Id,
            imgBox: formData.curData.ImgBox,
            imgStore: formData.curData.ImgStore,
            imgStreet: formData.curData.ImgStreet,
            status: formData.curData.Status,
            deposit: formData.curData.Deposit,
            countRate: formData.curData.CountRate,
            longitude: formData.curData.Longitude,
            latitude: formData.curData.Latitude,
          }
          if (formData.curData.password) {
            params.password = formData.curData.password
          }
          await axios.put('/admin/box', params)
        } catch (e) {
        }
        formData.dialogVisible = false
        func.loadData(formData.curPage)
      },
      async loadData(page = 1) {
        try {
          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valTypes != -1) {
            params.openType = formData.valTypes
          }
          if (formData.valResult != -1) {
            params.Res = formData.valResult
          }
          if (formData.valOrderNo) {
            params.orderNum = formData.valOrderNo
          }
          if (formData.valCode) {
            params.agentCode = formData.valCode
          }
          if (formData.valDevName) {
            params.devId = formData.valDevName
          }
          if (formData.valSiteName) {
            params.siteName = formData.valSiteName
          }
          if (formData.valuserId) {
            params.userid = formData.valuserId
          }

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          // if (formData.valDevName) {
          //   params.devName = formData.valDevName
          // }
          // console.log(JSON.stringify(formData.valTime));

          // console.log(JSON.stringify(params));

          let res = await axios.get(`/admin/openslot/get`, {
            params
          })
          // console.log(JSON.stringify(res), 123456);

          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreatedAt)
            switch (item.OpenType) {
              case 0:
                item.OpenTypeStr = t('openSlotsLit.types.all_slot')
                break;
              case 1:
                item.OpenTypeStr = t('openSlotsLit.types.all_empty_slot')
                break;
              case 2:
                item.OpenTypeStr = t('openSlotsLit.types.all_battery_slot')
                break;
              case 3:
                item.OpenTypeStr = t('openSlotsLit.types.Specify_slot')
                break;
              case 4:
                item.OpenTypeStr = t('openSlotsLit.types.full_charge_slot')
                break;
            }
            // item.UpdateAtStr = toDay(item.UpdateAt)
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {
      func.loadData()
    })
    return {
      func,
      render,
      formData,
      uploadUrl: cfg.uploadUrl,
      token: user.token,
      isOperator,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    padding-left: 2rem;
    // background-color: grey;

    // padding-top: 1rem;

    .slots {
      margin-top: 1rem;

      .slot {
        display: flex;
        margin-bottom: 0.5rem;

        >div {
          width: 8rem;

          &:first-child {
            width: 6rem;
          }
        }


      }
    }

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;

      >.item {
        width: 49%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        min-width: 32%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 70rem;
    // width:70vw;
  }
}

@media screen and (max-width:800px) {
  .content {
    >.card-box {
      .inp-list {
        >.item {
          width: 100%;
        }
      }
    }
  }
}</style>
