<template>
    <div class="content">
      <div class="card-box">
        <div class="header">
          <span class="title">{{$t("g.condition")}}</span>
        </div>
        <div class="inp-list">
          <div class="item">
            <span class="label">{{$t('decision.time')}}</span>
            <el-date-picker
                  class="inp"
                  v-model="formData.valTime"
                  value-format="YYYY-MM-DD"
                  size="small"
                  type="daterange"
                  unlink-panels
                  :range-separator="$t('g.to')"
                  :start-placeholder="$t('g.start_date')"
                  :end-placeholder="$t('g.end_date')"
                  :shortcuts="render.shortcuts"
                >
                </el-date-picker>
          </div>
          <div class="item" v-if="!isOperator()">
            <span class="label">{{$t('g.operator')}}</span>
            <el-select class="inp" size="small" v-model="formData.valOperator" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.operators"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </div>
          <!-- <div class="item">
            <span class="label">{{$t('member.status')}}</span>
            <el-select class="inp" size="small" v-model="formData.valStatus" :placeholder="$t('g.choose')">
                <el-option
                  v-for="item in render.statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </div> -->
          <div class="item">
            <span class="label">{{$t('order.name')}}</span>
            <el-input class="inp" size="small" v-model="formData.valTel" :placeholder="$t('order.inp.name')"></el-input>
          </div>
  
        </div>
        <div class="btn-ct">
          <el-button @click="func.search" class="search" type="primary" size="small">{{$t('g.search')}}</el-button>
        </div>
      </div>
      <div class="card-box data-table">
        <div class="header">
          <span class="title">{{$t("g.unit_num")}}:{{formData.total}}，{{$t("g.per_page")}}:{{formData.pageSize}}</span>
          <div class="header-icons">
            <!-- <i @click="func.loadData()" class="el-icon-download header-icon"></i> -->
            <el-icon @click="func.refresh()" class="header-icon"><Refresh /></el-icon>
          </div>
        </div>
        <el-table
              :data="formData.tableData"
              @row-click="func.showDetails"
              style="width: 100%">
              <el-table-column
                prop="OrderSn"
                :label="$t('order.no')"
                width="200">
              </el-table-column>
              <el-table-column
                prop="CardOrder.CardNum"
                width="160"
                :label="$t('card.number')">
              </el-table-column>
              <el-table-column
                prop="AgentCode"
                 v-if="!isOperator()"
                :label="$t('g.agent_code')"
                width="100">
              </el-table-column>
              <el-table-column
                prop="UserInfo.Nickname"
                width="120"
                :label="$t('bill.nickname')">
              </el-table-column>
              <el-table-column
                prop="UserInfo.UserComboInfo.Combo.Price"
                width="80"
                :label="$t('order.price')">
              </el-table-column>
              <el-table-column
                prop="PayWayStr"
                width="100"
                :label="$t('order.pay_way')">
              </el-table-column>
              <el-table-column
                prop="txt"
                width="100"
                :label="$t('order.combo_type')">
              </el-table-column>
              <el-table-column
                prop="CreatedAtStr"
                min-width="180"
                :label="$t('g.create_at')">
              </el-table-column>
              <el-table-column
                width="80"
                :label="$t('g.operation')">
                <template>
                  <!-- 预留操作 -->
                </template>
              </el-table-column>
            </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="func.pageChange"
          :page-size="formData.pageSize"
          :current-page="formData.curPage"
          :total="formData.total">
        </el-pagination>
      </div>
      <el-dialog
    :title="$t('g.detail')" 
    v-model="formData.dialogDisplayVisible"
    >
	<el-descriptions border column="3">
      <el-descriptions-item :label="$t('order.no')">{{ formData.curData.OrderSn }}</el-descriptions-item>
      <el-descriptions-item :label="$t('card.number')">{{ formData.curData.CardOrder.CardNum }}</el-descriptions-item>
      <el-descriptions-item :label="$t('g.agent_code')" v-if="!isOperator()">{{ formData.curData.AgentCode }}</el-descriptions-item>
      <el-descriptions-item :label="$t('bill.nickname')">{{ formData.curData.UserInfo.Nickname }}</el-descriptions-item>
      <el-descriptions-item :label="$t('order.price')">{{ formData.curData.UserInfo.UserComboInfo.Combo.Price }}</el-descriptions-item>
      <el-descriptions-item :label="$t('order.pay_way')">{{ formData.curData.PayWayStr }}</el-descriptions-item>
      <el-descriptions-item :label="$t('order.combo_type')">{{ formData.curData.txt }}</el-descriptions-item>
      <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreatedAtStr }}</el-descriptions-item>
    
	</el-descriptions>
  </el-dialog>
    </div>
  </template>
  
  <script>
  import { reactive, onMounted } from 'vue'
  import axios from 'axios'
  import { operators, updateOperators,shortcuts } from '@/data/default'
  import { toDay } from "@/common/func"
  import i18n from '@/data/i18n/index'
  import { isOperator } from '@/data/token'
  
  export default {
    name: 'OrderCombo',
    setup () {
      const t  = i18n.global.t
      const tableData = []
      const statuses = [
        {
          label: t('member.statuses.all'),
          value: -1
        },
        {
          label:t('member.statuses.unpay'),
          value: 0
        },
        {
          label: t('member.statuses.paid'),
          value: 1
        },
      ]
      const render = {
        shortcuts,
        statuses,
        operators
      }
      const formData = reactive({
        tableData,
        curData: {},
        valOperator: 0,
        valStatus: -1,
        valTime: '',
        dialogDisplayVisible:false,
        valTel: '',
        // pagination
        curPage: 1,
        pageSize: 10,
        total: 1
      })
      const func = {
        showDetails(row){
          formData.dialogDisplayVisible = true;
          formData.curData = row;
    },
        search () {
          console.log('formData', formData)
          func.loadData(1)
        },
        pageChange (val) {
          console.log('page change', val)
          func.loadData(val)
        },
        refresh(){
          formData.valOperator = 0
          formData.valStatus = -1
          formData.valTime = ''
          formData.valTel = ''
          func.loadData(1)
        },
        async loadData(page=1){
          try{
            formData.tableData = []
            formData.curPage = page
            let params = {
                page,
            }
            if(formData.valOperator){
              params.agentcode = formData.valOperator
            }
            if(formData.valTel){
              params.nickname = formData.valTel
            }
            if(formData.valStatus != -1){
              params.status = formData.valStatus
            }
            console.log(JSON.stringify(params));
            
            if(formData.valTime){
              params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
              params.endAt = Math.floor(Date.parse(formData.valTime[1]+" 23:59:59") / 1000)
            }
            let res = await axios.get(`/admin/order/card`,{
              params
            })
            formData.total = res.Total
            // console.log(JSON.stringify(res));
            
            res.List.map((item)=>{
              item.CreatedAtStr = toDay(item.CreatedAt)
              item.StatusStr = item.Status === 1 ? t('member.statuses.success') : t('member.statuses.failed')
              item.PayWayStr = ""
              switch (item.PayWay) {
                case 1:
                  item.PayWayStr = t('order.pay_ways.balance')
                  break;
                case 2:
                  item.PayWayStr = t('order.pay_ways.wechat')
                  break;
                case 3:
                  item.PayWayStr = t('order.pay_ways.combo')
                  break;
                case 4:
                  item.PayWayStr = t('order.pay_ways.paypal')
                  break;
                case 5:
                  item.PayWayStr = t('order.pay_ways.alipay')
                  break;
                case 6:
                  item.PayWayStr = t('order.pay_ways.cash')
                  break;
              }
              //套餐类型
              if(item.UserInfo&&item.UserInfo.UserComboInfo&&item.UserInfo.UserComboInfo.Combo&&item.UserInfo.UserComboInfo.Combo.Type==2){
                item.txt =  t('expenses.types.by_times')
              }else{
                item.txt =  t('expenses.types.by_month')
              }
              // console.log(JSON.stringify(item.ComboOrder.txt));
              
            })
            formData.tableData = res.List
            formData.pageSize = res.PageSize
          }catch(e){
            console.log("loadData",e)
          }
        }
      }
      onMounted(() => {
        console.log("onMounted")
        updateOperators()
        func.loadData()
      })
      return {
        func,
        render,
        formData,
        isOperator,
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .content{
    box-sizing: border-box;
    padding: 1.25rem;
    margin-bottom: 6.25rem;
    .inp-block{
      display: block;
    }
    >.card-box{
      font-size: 0.875rem;
      background-color: #fff;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      padding: 1.25rem;
      border-radius: 0.3rem;
      margin-bottom: 1.25rem;
      .header{
        text-align:left;
        margin-bottom: 1.25rem;
        position: relative;
        >.title{
          font-size:1rem;
          font-weight: 500;
        }
        >.header-icons{
          position: absolute;
          right: 10px;
          top: 0px;
          z-index: 100;
          .header-icon{
            font-size: 0.875rem;
            font-weight: 500;
            border: 1px solid #ddd;
            color:#969696;
            padding: 0.25rem;
            border-radius: 50%;
            color: pointer;
            margin-left: 0.625rem;
            cursor: pointer;
          }
        }
      }
      >.btn-ct{
        text-align: right;
        :deep(.el-button--small){
          padding: 0.625rem 2rem;
        }
      }
      .inp-list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        >.item{
          width: 49%;
          display: flex;
          align-items: center;
          margin-bottom: 1.25rem;
          .label{
            margin-right: 0.625rem;
            width: 4.375rem;
          }
          :deep(.el-range-editor--small.el-input__inner){
            flex: 1;
          }
          .inp{
            flex: 1;
          }
        }
      }
    }
    >.el-alert-my{
      margin-bottom: 1.25rem;
      box-shadow: $box-shadow;
      padding: 1.25rem;
      .el-alert-my-txt{
        font-size: 0.875rem;
        >.tips{
          font-weight: bold;
        }
      }
      :deep(.el-alert__closebtn){
        top: 1.25rem;
        font-size: 1rem;
      }
    }
    >.data-table{
      :deep(.el-table){
        .cell{
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .el-table__row{
          cursor: pointer;
        }
      }
    }
    >.pagination{
      text-align: right;
      margin-top: 1.25rem;
    }
    .el-form-row{
      display:flex;
      justify-content: space-between;
      :deep(.el-form-item){
        width: 49%;
      }
    }
    .el-select-my{
      text-align: left;
      display: block;
    }
  }
  </style>
  