<template>
  <div class="content">
    <div class="card-box">
      <div class="header">
        <span class="title">{{ $t("g.condition") }}</span>
      </div>
      <div class="inp-list">
        <div class="item">
          <span class="label">{{ $t("g.create_at") }}</span>
          <el-date-picker class="inp" v-model="formData.valTime" value-format="YYYY-MM-DD" size="small" type="daterange"
            unlink-panels :range-separator="$t('g.to')" :start-placeholder="$t('g.start_date')"
            :end-placeholder="$t('g.end_date')" :shortcuts="render.shortcuts">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="label">{{ $t("g.update_at") }}</span>
          <el-date-picker v-model="formData.valUpdateTime" :placeholder="$t('device.deadline') + $t('g.update_at')"
            type="datetime">
          </el-date-picker>

        </div>
        <div class="item">
          <span class="label">{{ $t("g.name") }}</span>
          <el-input class="inp" size="small" v-model="formData.valName" :placeholder="$t('device.holder')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t("g.operator") }}</span>
          <el-select class="inp" size="small" v-model="formData.valCode" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.operators" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.charge_status") }}</span>
          <el-select class="inp" size="small" v-model="formData.status" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.batteryStatuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.use_status.name") }}</span>
          <el-select class="inp" size="small" v-model="formData.useStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.useStatuses2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t("device.net_status.name") }}</span>
          <el-select class="inp" size="small" v-model="formData.onlineStatus" :placeholder="$t('g.choose')">
            <el-option v-for="item in render.onlineStatuses" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="label">{{ $t('device.device_id') }}</span>
          <el-input class="inp" size="small" v-model="formData.valDevName"
            :placeholder="$t('device.device_id')"></el-input>
        </div>
        <div class="item">
          <span class="label">{{ $t('member.mobile') }}</span>
          <el-input class="inp" size="small" v-model="formData.valPhone" :placeholder="$t('member.mobile')"></el-input>
        </div>
      </div>
      <div class="btn-ct">
        <el-button @click="func.clear" class="search" type="info" size="small">{{ $t('g.clear') }}</el-button>
        <el-button @click="func.search" class="search" type="primary" size="small">{{ $t('g.search') }}</el-button>
      </div>
    </div>
    <div class="card-box data-table">
      <div class="header">
        <span class="title">{{ $t("g.unit_num") }}:{{ formData.total }}，{{ $t("g.per_page") }}:{{ formData.pageSize
          }}</span>
        <div class="header-icons">
          <!-- <i @click="func.showAddDataDialog()" class="el-icon-plus header-icon"></i> -->
        </div>
      </div>
      <el-table :data="formData.tableData" :stripe="true" style="width: 100%" @row-click="func.showDetails">
        <el-table-column type="expand">
          <template #default="scope">
            <div class="table-detail">
              <div class="item">{{ $t('device.power') }}: {{ scope.row.Energy }}</div>
              <div class="item">{{ $t('device.slot_id') }}: {{ scope.row.EboxSlotId }}</div>
              <div class="item">{{ $t('device.soh') }}: {{ scope.row.Soh }}</div>
              <div class="item">{{ $t('device.volt') }}: {{ scope.row.Volt }}</div>
              <div class="item">{{ $t('device.cell_count') }}: {{ scope.row.CellCount }}</div>
              <div class="item">{{ $t('device.protect') }}: {{ scope.row.Protect }}</div>
              <div class="item">{{ $t('device.charge_status') }}: {{ scope.row.ChargeStatus }}</div>
              <div class="item">{{ $t('device.input_current') }}: {{ scope.row.InputCurrent }}</div>
              <div class="item">{{ $t('device.input_volt') }}: {{ scope.row.InputVolt }}</div>
              <div class="item">{{ $t('device.inner_input_current') }}: {{ scope.row.InnerInputCurrent }}</div>
              <div class="item">{{ $t('device.env_temp') }}: {{ scope.row.EnvTemp }}</div>
              <div class="item">{{ $t('device.cell_temp') }}: {{ scope.row.CellTemp }}</div>
              <div class="item">{{ $t('device.board_temp') }}: {{ scope.row.BoardTemp }}</div>
              <div class="item">{{ $t('device.longitude') }}: {{ scope.row.Longitude }}</div>
              <div class="item">{{ $t('device.latitude') }}: {{ scope.row.Latitude }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="Id" label="#" width="80">
        </el-table-column>
        <el-table-column prop="DevId" :label="$t('device.device_id')" width="240" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="EboxDevId" :label="$t('openSlotsLit.dev_id')" width="240" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="AgentCode" :label="$t('g.agent_code')" width="100">
        </el-table-column>
        <el-table-column :label="$t('device.holder')" width="140">
          <template #default="scope">
            <div>{{ scope.row.UserInfo?.Nickname || scope.row.BatteryUserName || '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="BatteryUserPhone" width="120" :label="$t('member.mobile')">
          <template #default="scope">
            <div>{{ scope.row.UserInfo?.Mobile || '-' || scope.row.BatteryUserPhone }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="Soc" width="120" :label="$t('device.soc')">
        </el-table-column>
        <el-table-column prop="Error" width="120" :label="$t('device.error_no')">
        </el-table-column>
        <el-table-column prop="ChargeCount" width="120" :label="$t('device.charge_count')">
        </el-table-column>
        <el-table-column width="130" :label="$t('device.charge_status')">
          <template #default="scope">
            <el-tag :type="scope.row.Status == 1 ? 'warning' : (scope.row.Status == 2 ? 'success' : 'info')"
              effect="dark">
              {{
                scope.row.Status == 1 ? $t('device.statuses.charging') : (scope.row.Status == 2 ?
                  $t('device.statuses.full') : $t('device.statuses.normal'))
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.move_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.State == 3 ? 'warning' : (scope.row.State == 0 ? 'success' : 'info')"
              effect="dark">
              {{
                render.moveStatusese[scope.row.State]
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.control_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.ControlState == 0 ? 'warning' : (scope.row.ControlState == 1 ? 'success' : 'info')"
              effect="dark">
              {{
                render.controlStatusese[scope.row.ControlState]
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.use_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.UseStatus == 2 ? 'warning' : (scope.row.UseStatus == 1 ? 'success' : 'info')"
              effect="dark">
              {{
                render.useStatuses[scope.row.UseStatus + 1].label
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="$t('device.net_status.name')">
          <template #default="scope">
            <el-tag :type="scope.row.NetStatus == 0 ? 'warning' : (scope.row.NetStatus == 1 ? 'success' : 'info')"
              effect="dark">
              {{
                render.onlineStatuses[scope.row.NetStatus + 1].label
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="Rate" width="170" :label="$t('device.rate')">
        </el-table-column>
        <el-table-column prop="Longitude" width="170" :label="$t('device.longitude')">
        </el-table-column>
        <el-table-column prop="Latitude" width="170" :label="$t('device.latitude')">
        </el-table-column>
        <el-table-column prop="CreateAtStr" min-width="200" :label="$t('g.create_at')">
        </el-table-column>
        <el-table-column prop="UpdateAtStr" min-width="200" :label="$t('g.update_at')">
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" @current-change="func.pageChange"
        :page-size="formData.pageSize" :current-page="formData.curPage" :total="formData.total">
      </el-pagination>
    </div>
    <el-dialog :title="$t('g.detail')" v-model="formData.dialogDisplayVisible" width="70%">
      <el-descriptions border>
        <el-descriptions-item :label="$t('device.device_id')">{{ formData.curData.DevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('openSlotsLit.dev_id')">{{ formData.curData.EboxDevId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.agent_code')">{{ formData.curData.AgentCode }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.holder')">{{ formData.curData.BatteryUserName }}</el-descriptions-item>
        <el-descriptions-item :label="$t('member.mobile')">{{ formData.curData.BatteryUserPhone
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.soc')">{{ formData.curData.Soc }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.error_no')">{{ formData.curData.Error }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.charge_count')">{{ formData.curData.ChargeCount
          }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.charge_status')">
          <el-tag :type="formData.curData.Status == 1 ? 'warning' : (formData.curData.Status == 2 ? 'success' : 'info')"
            effect="dark">
            {{
              formData.curData.Status == 1 ? $t('device.statuses.charging') : (formData.curData.Status == 2 ?
                $t('device.statuses.full') : $t('device.statuses.normal'))
            }}
          </el-tag>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('device.move_status.name')"> <el-tag
            :type="formData.curData.State == 3 ? 'warning' : (formData.curData.State == 0 ? 'success' : 'info')"
            effect="dark">
            {{
              render.moveStatusese[formData.curData.State]
            }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.control_status.name')"> <el-tag
            :type="formData.curData.ControlState == 0 ? 'warning' : (formData.curData.ControlState == 1 ? 'success' : 'info')"
            effect="dark">
            {{
              render.controlStatusese[formData.curData.ControlState]
            }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.use_status.name')"> <el-tag
            :type="formData.curData.UseStatus == 2 ? 'warning' : (formData.curData.UseStatus == 1 ? 'success' : 'info')"
            effect="dark">
            {{
              render.useStatuses[formData.curData.UseStatus + 1].label
            }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.net_status.name')"> <el-tag
            :type="formData.curData.NetStatus == 0 ? 'warning' : (formData.curData.NetStatus == 1 ? 'success' : 'info')"
            effect="dark">
            {{
              render.onlineStatuses[formData.curData.NetStatus + 1].label
            }}
          </el-tag></el-descriptions-item>
        <el-descriptions-item :label="$t('device.rate')">{{ formData.curData.Rate }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.longitude')">{{ formData.curData.Longitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.latitude')">{{ formData.curData.Latitude }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.address')">{{ formData.curData.address }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.power')">{{ formData.curData.Energy }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.slot_id')">{{ formData.curData.EboxSlotId }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.soh')">{{ formData.curData.Soh }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.volt')">{{ formData.curData.Volt / 10 }}V</el-descriptions-item>
        <el-descriptions-item :label="$t('device.cell_count')">{{ formData.curData.CellCount }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.protect')">{{ formData.curData.Protect }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.charge_status')">{{
          formData.curData.ChargeStatus }}</el-descriptions-item>
        <el-descriptions-item :label="$t('device.input_current')">{{
          formData.curData.InputCurrent / 10 }}A</el-descriptions-item>
        <el-descriptions-item :label="$t('device.input_volt')">{{
          formData.curData.InputVolt / 10 }}V</el-descriptions-item>
        <el-descriptions-item :label="$t('device.inner_input_current')">{{
          formData.curData.InnerInputCurrent / 10 }}A</el-descriptions-item>
        <el-descriptions-item :label="$t('device.env_temp')">{{ formData.curData.EnvTemp
          }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('device.cell_temp')">{{
          formData.curData.CellTemp }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('device.board_temp')">{{
          formData.curData.BoardTemp }}&#8451;</el-descriptions-item>
        <el-descriptions-item :label="$t('g.create_at')">{{ formData.curData.CreateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.update_at')">{{ formData.curData.UpdateAtStr }}</el-descriptions-item>
        <el-descriptions-item :label="$t('g.battery_update_at')">{{ formData.curData.BatUpdateAtStr
          }}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="default" @click="formData.dialogDisplayVisible = false">{{ $t('g.btn_cancel') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, onMounted, computed } from 'vue'
import { shortcuts, operators } from '@/data/default'
import { toDay } from '@/common/func'
import axios from "axios"
import i18n from '@/data/i18n/index'
import { ElMessage } from 'element-plus'
export default {
  name: 'BatteryList',
  components: {
  },
  setup() {
    const t = i18n.global.t
    const tableData = []
    const batteryStatuses = [

      {
        label: t('device.statuses.all'),
        value: 3
      },
      {
        label: t('device.statuses.normal'),
        value: 0
      },
      {
        label: t('device.statuses.charging'),
        value: 1
      },
      {
        label: t('device.statuses.full'),
        value: 2
      }

    ]
    const moveStatusese = [t('device.move_status.move'), t('device.move_status.still'), t('device.move_status.storage'), t('device.move_status.hibernate'),]
    const controlStatusese = [t('device.control_status.discharge'), t('device.control_status.charge'), t('device.control_status.load'), t('device.control_status.charged'), t('device.control_status.empty')]
    const onlineStatuses = [{
      label: t('device.statuses.all'),
      value: -1
    }, {
      label: t('device.net_status.offline'),
      value: 0
    }, {
      label: t('device.net_status.online'),
      value: 1
    }]
    const useStatuses = [{
      label: t('device.statuses.all'),
      value: -1
    }, {
      label: t('device.use_status.idle'),
      value: 0
    }, {
      label: t('device.use_status.cabinet'),
      value: 1
    }, {
      label: t('device.use_status.rider'),
      value: 2
    }, {
      label: t('device.use_status.uncabinet'),
      value: 3
    }]
    const useStatuses2 = [{
      label: t('device.statuses.all'),
      value: -1
    }, {
      label: t('device.use_status.idle'),
      value: 0
    }, {
      label: t('device.use_status.cabinet'),
      value: 1
    }, {
      label: t('device.use_status.rider'),
      value: 2
    }]
    const render = {
      shortcuts,
      operators,
      batteryStatuses,
      onlineStatuses,
      useStatuses,
      useStatuses2,
      moveStatusese,
      controlStatusese
    }
    const formData = reactive({
      tableData,
      curData: {},
      valTime: '',
      curPage: 1,
      pageSize: 10,
      status: 3,
      total: 0,
      valName: '',
      valCode: 0,
      valDevName: '',
      dialogDisplayVisible: false,
    })
    const func = {
      geocoder(lon, lat) {
        let params = {
          key: 'B2UBZ-JT7KN-BVRFJ-SJHR5-APMUJ-DEFGC',
          location: lat + ',' + lon,
        }
        axios({
          url: `/tenxun/ws/geocoder/v1/?key=` + params.key + '&location=' + params.location,
          method: 'get',
          headers: { 'content-type': 'application/json' },
        }).then(function (res) {
          console.log(res.result.address);
          formData.curData.address = res.result.address
        })
          .catch(function (err) {
            // handle error
            console.log(err, 230);
          })
      },
      async getAddress(lon, lat) {
        let params = {
          lon,
          lat
        }
        let res = await axios.get(`/admin/battery/address`, {
          params
        })
        return res
      },
      async showDetails(row) {
        formData.dialogDisplayVisible = true;
        formData.curData = row;
        if (row.Longitude && row.Latitude) {
          let address = await func.getAddress(row.Longitude, row.Latitude);
          formData.curData.address = address
        }

        // console.log(JSON.stringify(row),23);
      },
      search() {
        console.log('formData', formData)
        func.loadData()
      },
      pageChange(val) {
        console.log('page change', val)
        func.loadData(val)
      },
      clear() {
        formData.valName = '';
        formData.valCode = '';
        formData.valPhone = '';
        formData.status = '';
        formData.useStatus = -1;
        formData.onlineStatus = -1;
        formData.valTime = '';
        formData.valUpdateTime = '';

        func.loadData()
      },
      async loadData(page = 1) {
        try {

          formData.tableData = []
          formData.curPage = page
          let params = {
            page,
          }
          if (formData.valName) {
            params.name = formData.valName
          }
          if (formData.valUpdateTime) {
            params.UpdateEnd = Math.floor(Date.parse(formData.valUpdateTime) / 1000)
          }
          if (formData.valCode) {
            params.agentCode = formData.valCode
          }
          if (formData.valPhone) {
            params.phoneNum = formData.valPhone
          }
          params.status = formData.status
          console.log(JSON.stringify(formData.useStatus));

          if (formData.useStatus >= 0) {
            params.UseState = formData.useStatus
          }
          if (formData.onlineStatus >= 0) {
            params.NetState = formData.onlineStatus

          }


          console.log(JSON.stringify(params));

          if (formData.valTime) {
            params.startAt = Math.floor(Date.parse(formData.valTime[0]) / 1000)
            params.endAt = Math.floor(Date.parse(formData.valTime[1] + " 23:59:59") / 1000)
          }
          if (formData.valDevName) {
            params.devId = formData.valDevName
          }
          let res = await axios.get(`/admin/battery`, {
            params
          })
          formData.total = res.Total
          res.List.map((item) => {
            item.CreateAtStr = toDay(item.CreateAt)
            item.UpdateAtStr = toDay(item.UpdateAt)
            item.BatUpdateAtStr = item.BatUpdateAt != 0 ? toDay(item.BatUpdateAt) : t('g.null')
            item.BatUpdateAtStr = item.BatUpdateAt != 0 ? toDay(item.BatUpdateAt) : t('g.null')
          })
          formData.tableData = res.List
          formData.pageSize = res.PageSize
        } catch (e) {
          console.log("loadData", e)
        }
      }
    }
    onMounted(() => {

      func.loadData()

    })
    return {
      func,
      render,
      formData,
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 6.25rem;

  .table-detail {
    display: flex;
    flex-direction: column;
    margin-right: 9.375rem;
    padding-left: 4rem;
    padding-top: 1rem;

    >.item {
      margin-right: 0.625rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.625rem;
      color: #333333;

      >img {
        margin-left: 0.625rem;
        width: 4rem;
        height: 3rem;
        object-fit: cover;
      }
    }
  }

  .boximg {
    width: 4rem;
    height: 3rem;
    object-fit: cover;
  }

  >.card-box {
    font-size: 0.875rem;
    background-color: #fff;
    box-shadow: $box-shadow;
    box-sizing: border-box;
    padding: 1.25rem;
    border-radius: 0.3rem;
    margin-bottom: 1.25rem;

    .header {
      text-align: left;
      margin-bottom: 1.25rem;
      position: relative;

      >.title {
        font-size: 1rem;
        font-weight: 500;
      }

      >.header-icons {
        position: absolute;
        right: 10px;
        top: 0px;
        z-index: 100;

        .header-icon {
          font-size: 0.875rem;
          font-weight: 500;
          border: 1px solid #ddd;
          color: #969696;
          padding: 0.25rem;
          border-radius: 50%;
          color: pointer;
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }
    }

    >.btn-ct {
      text-align: right;

      :deep(.el-button--small) {
        padding: 0.625rem 2rem;
      }
    }

    .inp-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: left;

      >.item {
        width: 33%;
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .label {
          margin-right: 0.625rem;
          width: 4.375rem;
        }

        :deep(.el-range-editor--small.el-input__inner) {
          flex: 1;
        }

        .inp {
          flex: 1;
        }
      }
    }
  }

  >.el-alert-my {
    margin-bottom: 1.25rem;
    box-shadow: $box-shadow;
    padding: 1.25rem;

    .el-alert-my-txt {
      font-size: 0.875rem;

      >.tips {
        font-weight: bold;
      }
    }

    :deep(.el-alert__closebtn) {
      top: 1.25rem;
      font-size: 1rem;
    }
  }

  >.data-table {
    :deep(.el-table) {
      .cell {
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .el-table__row {
        cursor: pointer;
      }
    }
  }

  >.pagination {
    text-align: right;
    margin-top: 1.25rem;
  }

  .el-form-row {
    display: flex;
    justify-content: space-between;

    :deep(.el-form-item) {
      width: 49%;
    }

    &.line-3 {
      :deep(.el-form-item) {
        width: 33%;
      }
    }

    .inp {
      width: 100%;
    }

    .avatar-uploader {
      :deep(.el-upload) {
        border: 1px dashed #d9d9d9;
        border-radius: 0.375rem;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:hover {
          border-color: #409eff;
        }
      }
    }

    .avatar-uploader-icon {
      font-size: 1.75rem;
      color: #8c939d;
      width: 11rem;
      height: 9rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar {
      width: 11rem;
      height: 9rem;
      display: block;
      object-fit: cover;
    }
  }

  .el-select-my {
    text-align: left;
    display: block;
  }

  :deep(.m-dialog) {
    max-width: 80rem;
  }
}
</style>
